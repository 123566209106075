/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FunctionComponent } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import {
  addressInputAtom,
  addressLatAtom,
  addressLngAtom,
  systemLocationAddressAtom,
  systemLocationCityAtom,
  systemLocationStateAtom,
  systemLocationZipAtom,
} from "../../state/atoms/form";
import { canSubmitStep1Selector } from "../../state/selectors/form";

import Map from "../Map";

const Step1: FunctionComponent<{}> = () => {
  const { search } = useLocation();
  const history = useHistory();

  const [addressInput, setAddressInput] = useRecoilState(addressInputAtom);
  const [addressLng, setAddressLng] = useRecoilState(addressLngAtom);
  const [addressLat, setAddressLat] = useRecoilState(addressLatAtom);

  const setSystemLocationAddress = useSetRecoilState(systemLocationAddressAtom);
  const setSystemLocationCity = useSetRecoilState(systemLocationCityAtom);
  const setSystemLocationState = useSetRecoilState(systemLocationStateAtom);
  const setSystemLocationZip = useSetRecoilState(systemLocationZipAtom);
  const canSubmitStep = useRecoilValue(canSubmitStep1Selector);

  return (
    <>
      <h1>Create a Proposal</h1>
      <div className="form-group max-w-700px m-auto my-3 text-start">
        <h5>System location</h5>
        <div className="row justify-content-center">
          <Map
            coordinates={
              addressLat && addressLng
                ? {
                    lat: addressLat,
                    lng: addressLng,
                  }
                : undefined
            }
            className="col-12 px-1"
            input={
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Address"
                  id="address"
                  value={addressInput || ""}
                  onChange={(event) => {
                    setAddressInput(event.target.value);
                  }}
                />
                <label htmlFor="address">
                  Address<span className="text-primary ml-025">*</span>
                </label>
              </div>
            }
            onPlaceSelected={(location: any) => {
              if (!location) {
                setAddressInput(null);
                setSystemLocationCity(null);
                setSystemLocationZip(null);
                setSystemLocationState(null);
                setSystemLocationAddress(null);
                setAddressLat(null);
                setAddressLng(null);
                return;
              }

              const { autoCompletePlace, addressObject, coordinates } =
                location;

              setAddressInput(autoCompletePlace.formatted_address);
              setSystemLocationCity(addressObject.city);
              setSystemLocationZip(addressObject.postal_code);
              setSystemLocationState(addressObject.region);
              setSystemLocationAddress(
                `${addressObject.home} ${addressObject.street}`
              );
              setAddressLat(coordinates.lat);
              setAddressLng(coordinates.lng);
            }}
          />
          <div className="col-12 mt-3 px-2">
            <button
              type="submit"
              className="btn btn-primary w-100 font-weight-600"
              disabled={!canSubmitStep}
              onClick={() => {
                history.push(`/step-2${search}`);
              }}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step1;

import React, { FunctionComponent } from "react";

export enum TagType {
  buy = "buy",
  lease = "lease",
  loan = "loan",
}

const Tag: FunctionComponent<{ tagType: TagType }> = ({ tagType }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "3rem",
        fontSize: "0.75rem",
        borderRadius: "3px",
        textAlign: "center",
        padding: "0.125rem 0.25rem",
        fontWeight: "500",
        background:
          tagType === TagType.buy
            ? "#FFF7CD"
            : tagType === TagType.lease
            ? "#E9F8E8"
            : "#FDF1F7",
        color:
          tagType === TagType.buy
            ? "#7E6A00"
            : tagType === TagType.lease
            ? "#5E7151"
            : "#70435A",
      }}
    >
      <div>{tagType.toUpperCase()}</div>
    </div>
  );
};

export default Tag;

export function removeURLParameter(url: string, parameter: string) {
  const urlparts = url.split("?");
  if (urlparts.length >= 2) {
    const prefix = encodeURIComponent(parameter) + "=";
    const pars = urlparts[1].split(/[&;]/g);

    for (let i = pars.length; i-- > 0; ) {
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    return urlparts[0] + (pars.length > 0 ? "?" + pars.join("&") : "");
  }
  return url;
}

const NON_WORK_EMAIL_PROVIDERS = [
  "gmail.com",
  "hotmail.com",
  "outlook.com",
  "yahoo.com",
];

const isWorkEmail = (email: string): boolean => {
  return !NON_WORK_EMAIL_PROVIDERS.some((provider) => email.endsWith(provider));
};

export const isValidEmail = (
  email: string | undefined,
  nonWorkAllowed = false
): boolean => {
  if (!email) return false;

  if (!nonWorkAllowed && !isWorkEmail(email)) return false;

  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
};

export const getEmailValidationError = (
  email: string | undefined,
  nonWorkAllowed = false
): string => {
  if (!email) return "Email is required";
  if (!nonWorkAllowed && !isWorkEmail(email)) return "Enter work email";
  if (!isValidEmail(email, nonWorkAllowed)) return "Incorrect email address";
  return "";
};

export const isValidPassword = (password: string | undefined): boolean => {
  return (password?.length || 0) >= 8;
};

export const getPasswordValidationError = (
  password: string | undefined
): string => {
  if (!isValidPassword(password)) return "At least 8 characters";
  return "";
};

export const isValidPhoneNumber = (
  phoneNumber: string | undefined
): boolean => {
  if (!phoneNumber) return false;
  return !!phoneNumber.match(/^[+]?[(]?[0-9]{3}[)]?[0-9]{3}[0-9]{4,6}$/);
};

export const getPhoneNumberValidationError = (
  phoneNumber: string | undefined
): string => {
  if (!phoneNumber) return "";
  if (!isValidPhoneNumber(phoneNumber)) return "Incorrect phone number format";
  return "";
};

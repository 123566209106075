import React, { FunctionComponent } from "react";

const PageNotFound: FunctionComponent<{}> = () => {
  return (
    <>
      <h1>Oi vey...</h1>
      <h4 className="mt-4">Something went terribly wrong</h4>
    </>
  );
};

export default PageNotFound;

import { selector } from "recoil";
import { isValidEmail, isValidPhoneNumber } from "../../services/utils";

import { Proposal } from "../../types/proposal";

import {
  systemLocationAddressAtom,
  systemLocationCityAtom,
  systemLocationStateAtom,
  systemLocationZipAtom,
  systemDetailsSystemSizeAtom,
  systemDetailsEstProductionAtom,
  systemDetailsPricePerWAtom,
  systemDetailsUtilityRateAtom,
  // systemDetailsUtilityProviderAtom,
  systemDetailsIsAddingBatteryAtom,
  systemDetailsHasAdditionalBatteryAtom,
  systemDetailsAdditionalBatteryTotalCostAtom,
  systemDetailsAdditionalBatteryTotalCapacityAtom,
  customerDetailsEmailAtom,
  customerDetailsFirstNameAtom,
  customerDetailsIsSameAddressAtom,
  customerDetailsLastNameAtom,
  customerDetailsCityAtom,
  customerDetailsStateAtom,
  customerDetailsZipAtom,
  customerDetailsAddressAtom,
  customerDetailsPhoneNumberAtom,
  selectedPlansToDisplayAtom,
} from "../atoms/form";
import {
  appSettingsAtom,
  contextElectricityAccelerationAtom,
  contextInstallerIdAtom,
  contextITCRateAtom,
} from "../atoms/global";

export const canSubmitStep1Selector = selector<boolean>({
  key: "canSubmitStep1Selector",
  get: ({ get }) => {
    const systemLocationAddress = get(systemLocationAddressAtom);
    const systemLocationCity = get(systemLocationCityAtom);
    const systemLocationState = get(systemLocationStateAtom);
    const systemLocationZip = get(systemLocationZipAtom);

    return !!(
      systemLocationAddress &&
      systemLocationCity &&
      systemLocationState &&
      systemLocationZip
    );
  },
});

export const systemDetailsSystemSizeErrorSelector = selector<string | null>({
  key: "systemDetailsSystemSizeErrorSelector",
  get: ({ get }) => {
    const { minSystemSize, maxSystemSize } = get(appSettingsAtom);
    const systemDetailsSystemSize = get(systemDetailsSystemSizeAtom);
    if (systemDetailsSystemSize === null) {
      return null;
    } else if (systemDetailsSystemSize < minSystemSize) {
      return `Our minimum size is ${minSystemSize} kW`;
    } else if (systemDetailsSystemSize > maxSystemSize) {
      return `Our current limit is ${maxSystemSize} kW`;
    }
    return null;
  },
});

export const systemDetailsEstProductionErrorSelector = selector<string | null>({
  key: "systemDetailsEstProductionErrorSelector",
  get: ({ get }) => {
    const systemDetailsEstProduction = get(systemDetailsEstProductionAtom);
    if (systemDetailsEstProduction === null) {
      return null;
    } else if (systemDetailsEstProduction < 0) {
      return "Should be positive";
    }
    return null;
  },
});

export const systemDetailsPricePerWErrorSelector = selector<string | null>({
  key: "systemDetailsPricePerWErrorSelector",
  get: ({ get }) => {
    const { minPricePerW, maxPricePerW } = get(appSettingsAtom);
    const systemDetailsPricePerW = get(systemDetailsPricePerWAtom);
    if (systemDetailsPricePerW === null) {
      return null;
    } else if (systemDetailsPricePerW < minPricePerW) {
      return `Please check again`;
    } else if (systemDetailsPricePerW > maxPricePerW) {
      return `Our current limit is $${maxPricePerW}`;
    }
    return null;
  },
});

export const systemDetailsUtilityRateErrorSelector = selector<string | null>({
  key: "systemDetailsUtilityRateErrorSelector",
  get: ({ get }) => {
    const { minUtilityRate, maxUtilityRate } = get(appSettingsAtom);
    const systemDetailsUtilityRate = get(systemDetailsUtilityRateAtom);
    if (systemDetailsUtilityRate === null) {
      return null;
    } else if (systemDetailsUtilityRate < minUtilityRate * 100) {
      return `Rate too low (min ¢${minUtilityRate * 100})`;
    } else if (systemDetailsUtilityRate > maxUtilityRate * 100) {
      return `Rate too high (max ¢${maxUtilityRate * 100})`;
    }
    return null;
  },
});

export const canSubmitStep2Selector = selector<boolean>({
  key: "canSubmitStep2Selector",
  get: ({ get }) => {
    const systemDetailsSystemSize = get(systemDetailsSystemSizeAtom);
    const systemDetailsEstProduction = get(systemDetailsEstProductionAtom);
    const systemDetailsPricePerW = get(systemDetailsPricePerWAtom);
    const systemDetailsUtilityRate = get(systemDetailsUtilityRateAtom);
    // const systemDetailsUtilityProvider = get(systemDetailsUtilityProviderAtom);
    const systemDetailsHasAdditionalBattery = get(
      systemDetailsHasAdditionalBatteryAtom
    );
    const systemDetailsIsAddingBattery = get(systemDetailsIsAddingBatteryAtom);
    const systemDetailsAdditionalBatteryTotalCost =
      get(systemDetailsAdditionalBatteryTotalCostAtom) || 0;
    const systemDetailsAdditionalBatteryTotalCapacity =
      get(systemDetailsAdditionalBatteryTotalCapacityAtom) || 0;

    const systemDetailsSystemSizeError = get(
      systemDetailsSystemSizeErrorSelector
    );
    const systemDetailsEstProductionError = get(
      systemDetailsEstProductionErrorSelector
    );
    const systemDetailsPricePerWError = get(
      systemDetailsPricePerWErrorSelector
    );
    const systemDetailsUtilityRateError = get(
      systemDetailsUtilityRateErrorSelector
    );

    return !!(
      (
        systemDetailsSystemSize &&
        systemDetailsEstProduction &&
        systemDetailsPricePerW &&
        systemDetailsUtilityRate &&
        !systemDetailsSystemSizeError &&
        !systemDetailsEstProductionError &&
        !systemDetailsPricePerWError &&
        !systemDetailsUtilityRateError &&
        !systemDetailsIsAddingBattery &&
        (systemDetailsHasAdditionalBattery
          ? systemDetailsAdditionalBatteryTotalCost > 0 &&
            systemDetailsAdditionalBatteryTotalCapacity > 0
          : true)
      )
      // && systemDetailsUtilityProvider
    );
  },
});

export const isCustomerDetailsEmailValidSelector = selector<boolean>({
  key: "isCustomerDetailsEmailValidSelector",
  get: ({ get }) => {
    const customerDetailsEmail = get(customerDetailsEmailAtom);

    return !!(customerDetailsEmail && isValidEmail(customerDetailsEmail, true));
  },
});

export const isCustomerDetailsPhoneNumberValidSelector = selector<boolean>({
  key: "isCustomerDetailsPhoneNumberValidSelector",
  get: ({ get }) => {
    const customerDetailsPhoneNumber = get(customerDetailsPhoneNumberAtom);

    return !!(
      customerDetailsPhoneNumber &&
      isValidPhoneNumber(customerDetailsPhoneNumber)
    );
  },
});

export const canSubmitStep3Selector = selector<boolean>({
  key: "canSubmitStep3Selector",
  get: ({ get }) => {
    const customerDetailsFirstName = get(customerDetailsFirstNameAtom);
    const customerDetailsLastName = get(customerDetailsLastNameAtom);
    const isCustomerDetailsEmailValid = get(
      isCustomerDetailsEmailValidSelector
    );
    const isCustomerDetailsPhoneNumberValid = get(
      isCustomerDetailsPhoneNumberValidSelector
    );
    const customerDetailsIsSameAddress = get(customerDetailsIsSameAddressAtom);
    const customerDetailsAddress = get(customerDetailsAddressAtom);
    const customerDetailsCity = get(customerDetailsCityAtom);
    const customerDetailsState = get(customerDetailsStateAtom);
    const customerDetailsZip = get(customerDetailsZipAtom);

    return !!(
      customerDetailsFirstName &&
      customerDetailsLastName &&
      isCustomerDetailsEmailValid &&
      isCustomerDetailsPhoneNumberValid &&
      (customerDetailsIsSameAddress ||
        (customerDetailsAddress &&
          customerDetailsCity &&
          customerDetailsState &&
          customerDetailsZip))
    );
  },
});

export const proposalSelector = selector<Proposal | null>({
  key: "proposalSelector",
  get: ({ get }) => {
    const systemLocationAddress = get(systemLocationAddressAtom);
    const systemLocationCity = get(systemLocationCityAtom);
    const systemLocationState = get(systemLocationStateAtom);
    const systemLocationZip = get(systemLocationZipAtom);
    const systemDetailsSystemSize = get(systemDetailsSystemSizeAtom);
    const systemDetailsEstProduction = get(systemDetailsEstProductionAtom);
    const systemDetailsPricePerW = get(systemDetailsPricePerWAtom);
    const systemDetailsUtilityRate = get(systemDetailsUtilityRateAtom);
    // const systemDetailsUtilityProvider = get(systemDetailsUtilityProviderAtom);
    const systemDetailsHasAdditionalBattery = get(
      systemDetailsHasAdditionalBatteryAtom
    );
    const systemDetailsAdditionalBatteryTotalCost = get(
      systemDetailsAdditionalBatteryTotalCostAtom
    );
    const systemDetailsAdditionalBatteryTotalCapacity = get(
      systemDetailsAdditionalBatteryTotalCapacityAtom
    );
    const customerDetailsFirstName = get(customerDetailsFirstNameAtom);
    const customerDetailsLastName = get(customerDetailsLastNameAtom);
    const customerDetailsEmail = get(customerDetailsEmailAtom);
    const customerDetailsPhoneNumber = get(customerDetailsPhoneNumberAtom);
    const customerDetailsIsSameAddress = get(customerDetailsIsSameAddressAtom);
    const customerDetailsAddress = get(customerDetailsAddressAtom);
    const customerDetailsCity = get(customerDetailsCityAtom);
    const customerDetailsState = get(customerDetailsStateAtom);
    const customerDetailsZip = get(customerDetailsZipAtom);
    const selectedPlansToDisplay = get(selectedPlansToDisplayAtom);

    const proposal: Proposal = {
      installer: {
        id: get(contextInstallerIdAtom) || "",
      },
      prospect: {
        firstName: customerDetailsFirstName || "",
        lastName: customerDetailsLastName || "",
        email: customerDetailsEmail || "",
        phoneNumber: customerDetailsPhoneNumber || "",
        isSameAddress: customerDetailsIsSameAddress,
        location: {
          address:
            (customerDetailsIsSameAddress
              ? systemLocationAddress
              : customerDetailsAddress) || "",
          city:
            (customerDetailsIsSameAddress
              ? systemLocationCity
              : customerDetailsCity) || "",
          state:
            (customerDetailsIsSameAddress
              ? systemLocationState
              : customerDetailsState) || "",
          zip:
            (customerDetailsIsSameAddress
              ? systemLocationZip
              : customerDetailsZip) || "",
        },
      },
      deal: {
        systemLocation: {
          address: systemLocationAddress || "",
          city: systemLocationCity || "",
          state: systemLocationState || "",
          zip: systemLocationZip || "",
        },
        systemDetails: {
          systemSize: systemDetailsSystemSize || 0,
          estProduction: systemDetailsEstProduction || 0,
          pricePerW: systemDetailsPricePerW || 0,
          utilityRate: systemDetailsUtilityRate || 0,
          // utilityProvider: systemDetailsUtilityProvider || "",
        },
        hasAdditionalBattery: systemDetailsHasAdditionalBattery,
        batteryDetails: {
          totalCost: systemDetailsAdditionalBatteryTotalCost || 0,
          totalCapacity: systemDetailsAdditionalBatteryTotalCapacity || 0,
        },
      },
      selectedPlansToDisplay,
    };

    return proposal;
  },
});

export const getPlansTooltipMessagesSelector = selector<{
  buy: string;
  lease: string;
  loan: string;
}>({
  key: "getPlansTooltipMessagesSelector",
  get: ({ get }) => {
    const locationState = get(systemLocationStateAtom);
    const systemDetailsUtilityRate = get(
      systemDetailsUtilityRateAtom
    )?.toLocaleString("en", {
      useGrouping: true,
    });
    const ITCRatePercentage = get(contextITCRateAtom) * 100;

    const electricityAccelerationPercentage =
      get(contextElectricityAccelerationAtom) * 100;

    return {
      buy:
        locationState && locationState === "NY"
          ? `Based on the current utility rate of ¢${systemDetailsUtilityRate}, ${ITCRatePercentage}% federal state incentive (ITC), NY state tax credit use, and estimated electricity cost increase of ${electricityAccelerationPercentage}%.`
          : `Based on the current utility rate of ¢${systemDetailsUtilityRate}, ${ITCRatePercentage}% federal state incentive (ITC), and estimated electricity cost increase of ${electricityAccelerationPercentage}%.`,
      lease: `Based on the current utility rate of ¢${systemDetailsUtilityRate}, and estimated electricity cost increase of ${electricityAccelerationPercentage}%.`,
      loan: `Based on the current utility rate of ¢${systemDetailsUtilityRate}, ${ITCRatePercentage}% federal state incentive (ITC), and estimated electricity cost increase of ${electricityAccelerationPercentage}%.`,
    };
  },
});

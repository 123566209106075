import { atom } from "recoil";
import { TagType } from "../../components/Tag";
import { LoanDetails, PlanSaving } from "../../types/plans";
import { SelectedPlansToDisplay } from "../../types/proposal";

// ----------------------------------------------------------------
// Step 1
// ----------------------------------------------------------------

export const addressInputAtom = atom<string | null>({
  key: "addressInputAtom",
  default: null,
});

export const addressLatAtom = atom<number | null>({
  key: "addressLatAtom",
  default: null,
});

export const addressLngAtom = atom<number | null>({
  key: "addressLngAtom",
  default: null,
});

export const systemLocationAddressAtom = atom<string | null>({
  key: "systemLocationAddressAtom",
  default: null,
});

export const systemLocationCityAtom = atom<string | null>({
  key: "systemLocationCityAtom",
  default: null,
});

export const systemLocationStateAtom = atom<string | null>({
  key: "systemLocationStateAtom",
  default: null,
});

export const systemLocationZipAtom = atom<string | null>({
  key: "systemLocationZipAtom",
  default: null,
});

// ----------------------------------------------------------------
// Step 2
// ----------------------------------------------------------------

export const systemDetailsSystemSizeAtom = atom<number | null>({
  key: "systemDetailsSystemSizeAtom",
  default: null,
});

export const systemDetailsEstProductionAtom = atom<number | null>({
  key: "systemDetailsEstProductionAtom",
  default: null,
});

export const systemDetailsPricePerWAtom = atom<number | null>({
  key: "systemDetailsPricePerWAtom",
  default: null,
});

export const systemDetailsUtilityRateAtom = atom<number | null>({
  key: "systemDetailsUtilityRateAtom",
  default: null,
});

export const systemDetailsUtilityProviderAtom = atom<string | null>({
  key: "systemDetailsUtilityProviderAtom",
  default: null,
});

export const systemDetailsIsAddingBatteryAtom = atom<boolean>({
  key: "systemDetailsIsAddingBatteryAtom",
  default: false,
});

export const systemDetailsHasAdditionalBatteryAtom = atom<boolean>({
  key: "systemDetailsHasAdditionalBatteryAtom",
  default: false,
});

export const systemDetailsAdditionalBatteryTotalCostAtom = atom<number | null>({
  key: "systemDetailsAdditionalBatteryTotalCostAtom",
  default: null,
});

export const systemDetailsAdditionalBatteryTotalCapacityAtom = atom<
  number | null
>({
  key: "systemDetailsAdditionalBatteryTotalCapacityAtom",
  default: null,
});

export const systemDetailsIsCalculatingBatteryCostAtom = atom<boolean>({
  key: "systemDetailsIsCalculatingBatteryCostAtom",
  default: false,
});

export const systemDetailsAdditionalBatteryCalculatedMonthlyCostAtom = atom<
  number | null
>({
  key: "systemDetailsAdditionalBatteryCalculatedMonthlyCostAtom",
  default: null,
});

export const systemDetailsIsEditingPlanAtom = atom<boolean>({
  key: "systemDetailsIsEditingPlanAtom",
  default: false,
});

export const calculatedPlansPayNowAtom = atom<number | null>({
  key: "calculatedPlansPayNowAtom",
  default: null,
});

export const calculatedPlansFixedAtom = atom<number | null>({
  key: "calculatedPlansFixedAtom",
  default: null,
});

export const calculatedPlansPayLaterAtom = atom<number | null>({
  key: "calculatedPlansPayLaterAtom",
  default: null,
});

export const calculatedPlansLockYourRateAtom = atom<number | null>({
  key: "calculatedPlansLockYourRateAtom",
  default: null,
});

export const calculatedPlansYearlyAtom = atom<number | null>({
  key: "calculatedPlansYearlyAtom",
  default: null,
});

export const calculatedPlansSavingDetailsFixedAtom = atom<PlanSaving>({
  key: "calculatedPlansSavingDetailsFixedAtom",
  default: {
    percentage: 0,
    lifeTimeSavings: 0,
  },
});

export const calculatedPlansSavingDetailsPayNowAtom = atom<PlanSaving>({
  key: "calculatedPlansSavingDetailsPayNowAtom",
  default: {
    percentage: 0,
    lifeTimeSavings: 0,
  },
});

export const calculatedPlansSavingDetailsPayLaterAtom = atom<PlanSaving>({
  key: "calculatedPlansSavingDetailsPayLaterAtom",
  default: {
    percentage: 0,
    lifeTimeSavings: 0,
  },
});

export const calculatedLoanDetailsAtom = atom<LoanDetails | null>({
  key: "calculatedLoanDetailsAtom",
  default: null,
});

export const selectedPlansToDisplayAtom = atom<SelectedPlansToDisplay>({
  key: "selectedPlansToDisplayAtom",
  default: {
    [TagType.buy]: true,
    [TagType.lease]: true,
    [TagType.loan]: true,
  },
});

// ----------------------------------------------------------------
// Step 3
// ----------------------------------------------------------------

export const customerDetailsFirstNameAtom = atom<string | null>({
  key: "customerDetailsFirstNameAtom",
  default: null,
});

export const customerDetailsLastNameAtom = atom<string | null>({
  key: "customerDetailsLastNameAtom",
  default: null,
});

export const customerDetailsEmailAtom = atom<string | null>({
  key: "customerDetailsEmailAtom",
  default: null,
});

export const customerDetailsPhoneNumberAtom = atom<string | null>({
  key: "customerDetailsPhoneNumberAtom",
  default: null,
});

export const customerDetailsIsSameAddressAtom = atom<boolean>({
  key: "customerDetailsIsSameAddressAtom",
  default: true,
});

export const customerDetailsAddressAtom = atom<string | null>({
  key: "customerDetailsAddressAtom",
  default: null,
});

export const customerDetailsCityAtom = atom<string | null>({
  key: "customerDetailsCityAtom",
  default: null,
});

export const customerDetailsStateAtom = atom<string | null>({
  key: "customerDetailsStateAtom",
  default: null,
});

export const customerDetailsZipAtom = atom<string | null>({
  key: "customerDetailsZipAtom",
  default: null,
});

// ----------------------------------------------------------------
// Proposal summary
// ----------------------------------------------------------------

export const proposalIdAtom = atom<string | null>({
  key: "proposalIdAtom",
  default: null,
});

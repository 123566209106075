import React, { FunctionComponent } from "react";
import { Link, useLocation } from "react-router-dom";

import envelopeIcon from "../../assets/images/envelope-icon.svg";

const ResetPasswordComplete: FunctionComponent<{}> = () => {
  const { search } = useLocation();
  return (
    <div className="container-sm text-center m-auto">
      <div
        className="card card-body mt-4 mb-2 p-4 rounded m-auto"
        style={{ width: "23.5rem" }}
      >
        <div className="m-auto rounded-circle bg-lighten-primary p-2 d-flex justify-content-center align-items-center">
          <img src={envelopeIcon} alt="" width="22px" />
        </div>
        <div className="mt-2">
          An email has been sent to reset your
          <br />
          password. Check your inbox!
        </div>
      </div>
      <Link to={`/sign-in${search}`}>Back to log in</Link>
    </div>
  );
};

export default ResetPasswordComplete;

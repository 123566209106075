import { atom } from "recoil";
import { Installer } from "../../types/installers";
import { AppSettings } from "../../types/app-settings";
import { UsaState } from "../../types/proposal";

export const contextInstallerIdAtom = atom<string | null>({
  key: "contextInstallerIdAtom",
  default: null,
});

export const contextInstallerAtom = atom<Installer | null>({
  key: "contextInstallerAtom",
  default: null,
});

export const appSettingsAtom = atom<AppSettings>({
  key: "appSettingsAtom",
  default: {
    allowBattery: false,
    discountAlertThreshold: 0.03,
    discountLockThreshold: 0.05,
    downPaymentDefault: 0,
    downPaymentStep: 0.01,
    minUtilityRate: 10,
    maxUtilityRate: 30,
    minPricePerW: 5,
    maxPricePerW: 10,
    minSystemSize: 3,
    maxSystemSize: 30,
    roles: [],
  },
});

export const enabledStatesAtom = atom<UsaState[]>({
  key: "enabledStatesAtom",
  default: [],
});

export const contextRecaptchaToken = atom<string | null>({
  key: "contextRecaptchaToken",
  default: null,
});

export const contextRecaptchaExpire = atom<boolean>({
  key: "contextRecaptchaExpire",
  default: true,
});

export const contextRecaptchaError = atom<boolean>({
  key: "contextRecaptchaError",
  default: false,
});

export const contextResetRecaptcha = atom<boolean>({
  key: "contextResetRecaptcha",
  default: false,
});

export const contextShowStripeConnectWizard = atom<boolean>({
  key: "contextShowStripeConnectWizard",
  default: false,
});

export const contextShowStripeConnectTopBar = atom<boolean>({
  key: "contextShowStripeConnectTopBar",
  default: false,
});

export const contextITCRateAtom = atom<number>({
  key: "contextITCRateAtom",
  default: 0.3,
});

export const contextElectricityAccelerationAtom = atom<number>({
  key: "contextElectricityAccelerationAtom",
  default: 0.203,
});

import React, { FunctionComponent, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import {
  letsGetToKnowFirstNameAtom,
  letsGetToKnowLastNameAtom,
  letsGetToKnowPhoneNumberAtom,
  letsGetToKnowRoleAtom,
  signedInUserAtom,
} from "../../state/atoms/auth";
import { toastDataAtom } from "../../state/atoms/ui";
import { canSubmitLetsGetToKnowSelector } from "../../state/selectors/auth";
import { ToastType } from "../Toast";
import { SubmissionStates } from "../../types/ui";
import {
  getUser,
  sendUserEmailVerification,
  signUserOut,
  upsertSalesRepUser,
} from "../../services/firebase";
import { Timestamp } from "firebase/firestore";
import { appSettingsAtom } from "../../state/atoms/global";
import { getPhoneNumberValidationError } from "../../services/utils";
import { SalesRep } from "../../types/user";

const LetsGetToKnow: FunctionComponent<{}> = () => {
  const [toastData, setToastData] = useRecoilState(toastDataAtom);

  const [signedInUser, setSignedInUser] = useRecoilState(signedInUserAtom);

  const [firstName, setFirstName] = useRecoilState(letsGetToKnowFirstNameAtom);
  const [lastName, setLastName] = useRecoilState(letsGetToKnowLastNameAtom);
  const appSettings = useRecoilValue(appSettingsAtom);
  const [phoneNumber, setPhoneNumber] = useRecoilState(
    letsGetToKnowPhoneNumberAtom
  );
  const [role, setRole] = useRecoilState(letsGetToKnowRoleAtom);

  const [phoneNumberError, setPhoneNumberError] = useState("");

  const canSubmit = useRecoilValue(canSubmitLetsGetToKnowSelector);

  const [signUpSubmissionStage, setSignUpSubmissionStage] =
    useState<SubmissionStates>(SubmissionStates.NONE);

  if (!signedInUser) {
    return <></>;
  }

  const onSubmit = async (
    evt: React.FormEvent<HTMLFormElement | HTMLButtonElement>
  ) => {
    try {
      evt.preventDefault();
      setSignUpSubmissionStage(SubmissionStates.SUBMITTING);

      if (canSubmit) {
        const updatedUser: SalesRep = {
          ...signedInUser,
          firstName: firstName || "",
          lastName: lastName || "",
          phoneNumber: phoneNumber || "",
          role: role || "",
          profileUpdated: true,
          updatedAt: Timestamp.now(),
        };

        const user = await getUser();
        if (user && !user.emailVerified) {
          await sendUserEmailVerification(user!);
        }

        await upsertSalesRepUser(updatedUser);
        setSignUpSubmissionStage(SubmissionStates.SUCCESS);
        setSignedInUser(updatedUser);
      }
    } catch (err) {
      setSignUpSubmissionStage(SubmissionStates.ERROR);
      setToastData([
        ...toastData,
        {
          toastType: ToastType.info,
          header: "Could not update your profile",
        },
      ]);
    }
  };

  return (
    <div className="container-sm text-center m-auto">
      <h5 className="font-weight-600">Let&apos;s get to know you</h5>
      <form onSubmit={onSubmit}>
        <div className="row my-2">
          <div className="form-floating col-12">
            <input
              type="text"
              className="form-control"
              placeholder="First name"
              autoComplete="given-name"
              id="first-name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              disabled={signUpSubmissionStage === SubmissionStates.SUBMITTING}
            />
            <label htmlFor="first-name">
              First name<span className="text-primary ml-025">*</span>
            </label>
          </div>
          <div className="form-floating col-12 mt-2">
            <input
              type="text"
              className="form-control"
              placeholder="Last name"
              autoComplete="family-name"
              id="last-name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              disabled={signUpSubmissionStage === SubmissionStates.SUBMITTING}
            />
            <label htmlFor="last-name">
              Last name<span className="text-primary ml-025">*</span>
            </label>
          </div>
          <div className="form-floating col-12 mt-2">
            <input
              type="tel"
              className="form-control"
              placeholder="Phone number"
              autoComplete="tel"
              id="phone-number"
              value={phoneNumber}
              onChange={(e) => {
                const { value } = e.target;
                setPhoneNumber(value);
                setPhoneNumberError(getPhoneNumberValidationError(value));
              }}
              disabled={signUpSubmissionStage === SubmissionStates.SUBMITTING}
            />
            <label htmlFor="phone-number">Phone number</label>
          </div>
          {phoneNumberError && (
            <div className="text-primary text-start text-small">
              {phoneNumberError}
            </div>
          )}
          <div className="form-floating col-12 mt-2">
            <select
              className="form-control"
              id="role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              disabled={signUpSubmissionStage === SubmissionStates.SUBMITTING}
            >
              <option value="">Select your role</option>
              {appSettings.roles.map((role) => (
                <option key={role.id} value={role.name}>
                  {role.name}
                </option>
              ))}
              <option key={"other"} value={"Other"}>
                Other
              </option>
            </select>
            <label htmlFor="role">
              Role<span className="text-primary ml-025">*</span>
            </label>
          </div>
          <div className="col-12 mt-2">
            <button
              type="submit"
              className="btn btn-primary w-100 font-weight-600"
              disabled={
                !canSubmit ||
                signUpSubmissionStage === SubmissionStates.SUBMITTING
              }
              onSubmit={onSubmit}
            >
              Continue
            </button>
          </div>
        </div>
      </form>
      <div
        className="link"
        role="button"
        onClick={() => signUserOut(setSignedInUser)}
      >
        Back to log in
      </div>
    </div>
  );
};

export default LetsGetToKnow;

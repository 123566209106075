import React, { FunctionComponent, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import { sendUserResetPassword } from "../../services/firebase";
import { getEmailValidationError, isValidEmail } from "../../services/utils";

import { SubmissionStates } from "../../types/ui";
import { AuthErrorCodes } from "firebase/auth";
import {
  contextRecaptchaToken,
  contextResetRecaptcha,
} from "../../state/atoms/global";
import { useRecoilValue, useSetRecoilState } from "recoil";

const ResetPassword: FunctionComponent<{}> = () => {
  const { search } = useLocation();
  const history = useHistory();

  const [resetPasswordEmail, setResetPasswordEmail] = useState("");
  const [canSubmitResetPasswordEmail, setCanSubmitResetPasswordEmail] =
    useState(false);
  const [
    resetPasswordEmailSubmittingStage,
    setResetPasswordEmailSubmittingStage,
  ] = useState<SubmissionStates>(SubmissionStates.NONE);

  const [resetPasswordEmailError, setResetPasswordEmailError] = useState("");
  const [resetPasswordEmailBlurred, setResetPasswordEmailBlurred] =
    useState(false);

  const recaptchaToken = useRecoilValue(contextRecaptchaToken);
  const setResetRecaptcha = useSetRecoilState(contextResetRecaptcha);

  useEffect(() => {
    const sendResetPassword = async () => {
      if (resetPasswordEmailSubmittingStage !== SubmissionStates.SUBMITTING) {
        return;
      }
      if (recaptchaToken) {
        setResetRecaptcha(true);
        if (canSubmitResetPasswordEmail) {
          try {
            setResetPasswordEmailError("");
            await sendUserResetPassword(resetPasswordEmail);
            setResetPasswordEmailSubmittingStage(SubmissionStates.SUCCESS);
            history.push(`/reset-password-complete${search}`);
            return;
          } catch (err) {
            const { code } = (err as { code: string }) || {};
            switch (code) {
              case AuthErrorCodes.USER_DELETED:
                setResetPasswordEmailError("Unrecognized email address");
                break;
              default:
                setResetPasswordEmailError("Please try again later");
                break;
            }
            return setResetPasswordEmailSubmittingStage(SubmissionStates.ERROR);
          }
        }
        setResetPasswordEmailSubmittingStage(SubmissionStates.SUCCESS);
      }
    };
    sendResetPassword();
  }, [recaptchaToken, resetPasswordEmailSubmittingStage]);

  useEffect(() => {
    if (recaptchaToken) {
      setResetPasswordEmailSubmittingStage(SubmissionStates.SUBMITTING);
    }
  }, [recaptchaToken]);

  const onSubmitResetPasswordEmail = async (
    evt: React.FormEvent<HTMLFormElement | HTMLButtonElement>
  ) => {
    evt.preventDefault();
    window.grecaptcha.execute();
  };

  useEffect(() => {
    setCanSubmitResetPasswordEmail(isValidEmail(resetPasswordEmail));
  }, [resetPasswordEmail]);

  return (
    <div className="container-sm text-center m-auto">
      <h5 className="font-weight-600 mb-2">Reset Password</h5>
      <div className="my-1 text-center">
        Enter the work email address associated <br /> with your Rhino account.
      </div>
      <form className="mb-2" onSubmit={onSubmitResetPasswordEmail}>
        <div className="row mt-2">
          <div className="form-floating col-12">
            <input
              type="email"
              className="form-control"
              placeholder="Work email"
              autoComplete="email"
              id="email"
              value={resetPasswordEmail}
              onFocus={() => setResetPasswordEmailBlurred(false)}
              onChange={(e) => {
                const { value } = e.target;
                setResetPasswordEmail(value);
                setResetPasswordEmailError(getEmailValidationError(value));
              }}
              onBlur={() => setResetPasswordEmailBlurred(true)}
              disabled={
                resetPasswordEmailSubmittingStage ===
                  SubmissionStates.SUBMITTING ||
                resetPasswordEmailSubmittingStage === SubmissionStates.SUCCESS
              }
            />
            <label htmlFor="email">
              Work email<span className="text-primary ml-025">*</span>
            </label>
            {resetPasswordEmailBlurred && resetPasswordEmailError && (
              <div className="pl-1 text-start text-primary text-small">
                {resetPasswordEmailError}
              </div>
            )}
          </div>
          <div className="col-12 mt-2">
            <button
              type="submit"
              className="btn btn-primary w-100 font-weight-600"
              disabled={
                !canSubmitResetPasswordEmail ||
                resetPasswordEmailSubmittingStage ===
                  SubmissionStates.SUBMITTING ||
                resetPasswordEmailSubmittingStage === SubmissionStates.SUCCESS
              }
              onSubmit={onSubmitResetPasswordEmail}
            >
              Continue
            </button>
          </div>
        </div>
      </form>
      <Link to={`/sign-in${search}`}>Back to log in</Link>
    </div>
  );
};

export default ResetPassword;

import React, { FunctionComponent, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useResetRecoilState } from "recoil";

import {
  addressInputAtom,
  addressLatAtom,
  addressLngAtom,
  customerDetailsAddressAtom,
  customerDetailsCityAtom,
  customerDetailsEmailAtom,
  customerDetailsPhoneNumberAtom,
  customerDetailsFirstNameAtom,
  customerDetailsIsSameAddressAtom,
  customerDetailsLastNameAtom,
  customerDetailsStateAtom,
  customerDetailsZipAtom,
  systemDetailsAdditionalBatteryTotalCapacityAtom,
  systemDetailsAdditionalBatteryTotalCostAtom,
  systemDetailsEstProductionAtom,
  systemDetailsHasAdditionalBatteryAtom,
  systemDetailsUtilityProviderAtom,
  systemDetailsIsAddingBatteryAtom,
  systemDetailsPricePerWAtom,
  systemDetailsSystemSizeAtom,
  systemDetailsUtilityRateAtom,
  systemLocationAddressAtom,
  systemLocationCityAtom,
  systemLocationStateAtom,
  systemLocationZipAtom,
  selectedPlansToDisplayAtom,
} from "../state/atoms/form";

const StartFresh: FunctionComponent<{}> = () => {
  const { search } = useLocation();
  const history = useHistory();

  const resetAddressInput = useResetRecoilState(addressInputAtom);
  const resetAddressLat = useResetRecoilState(addressLatAtom);
  const resetAddressLng = useResetRecoilState(addressLngAtom);

  const resetSystemLocationAddress = useResetRecoilState(
    systemLocationAddressAtom
  );
  const resetSystemLocationCity = useResetRecoilState(systemLocationCityAtom);
  const resetSystemLocationState = useResetRecoilState(systemLocationStateAtom);
  const resetSystemLocationZip = useResetRecoilState(systemLocationZipAtom);

  const resetSystemDetailsSystemSize = useResetRecoilState(
    systemDetailsSystemSizeAtom
  );
  const resetSystemDetailsEstProduction = useResetRecoilState(
    systemDetailsEstProductionAtom
  );
  const resetSystemDetailsPricePerW = useResetRecoilState(
    systemDetailsPricePerWAtom
  );
  const resetSystemDetailsUtilityRateAtom = useResetRecoilState(
    systemDetailsUtilityRateAtom
  );
  const resetSystemDetailsUtilityProviderAtom = useResetRecoilState(
    systemDetailsUtilityProviderAtom
  );
  const resetSystemDetailsIsAddingBattery = useResetRecoilState(
    systemDetailsIsAddingBatteryAtom
  );
  const resetSystemDetailsHasAdditionalBattery = useResetRecoilState(
    systemDetailsHasAdditionalBatteryAtom
  );
  const resetSystemDetailsAdditionalBatteryTotalCost = useResetRecoilState(
    systemDetailsAdditionalBatteryTotalCostAtom
  );
  const resetSystemDetailsAdditionalBatteryTotalCapacity = useResetRecoilState(
    systemDetailsAdditionalBatteryTotalCapacityAtom
  );

  const resetCustomerDetailsFirstName = useResetRecoilState(
    customerDetailsFirstNameAtom
  );
  const resetCustomerDetailsLastName = useResetRecoilState(
    customerDetailsLastNameAtom
  );
  const resetCustomerDetailsEmail = useResetRecoilState(
    customerDetailsEmailAtom
  );

  const resetCustomerDetailsPhoneNumber = useResetRecoilState(
    customerDetailsPhoneNumberAtom
  );

  const resetCustomerDetailsIsSameAddress = useResetRecoilState(
    customerDetailsIsSameAddressAtom
  );
  const resetCustomerDetailsAddress = useResetRecoilState(
    customerDetailsAddressAtom
  );
  const resetCustomerDetailsCity = useResetRecoilState(customerDetailsCityAtom);
  const resetCustomerDetailsState = useResetRecoilState(
    customerDetailsStateAtom
  );
  const resetCustomerDetailsZip = useResetRecoilState(customerDetailsZipAtom);
  const resetSelectedPlansToDisplay = useResetRecoilState(
    selectedPlansToDisplayAtom
  );

  useEffect(() => {
    resetAddressInput();
    resetAddressLat();
    resetAddressLng();
    resetSystemLocationAddress();
    resetSystemLocationCity();
    resetSystemLocationState();
    resetSystemLocationZip();
    resetSystemDetailsSystemSize();
    resetSystemDetailsEstProduction();
    resetSystemDetailsPricePerW();
    resetSystemDetailsUtilityRateAtom();
    resetSystemDetailsUtilityProviderAtom();
    resetSystemDetailsIsAddingBattery();
    resetSystemDetailsHasAdditionalBattery();
    resetSystemDetailsAdditionalBatteryTotalCost();
    resetSystemDetailsAdditionalBatteryTotalCapacity();
    resetCustomerDetailsFirstName();
    resetCustomerDetailsLastName();
    resetCustomerDetailsEmail();
    resetCustomerDetailsPhoneNumber();
    resetCustomerDetailsIsSameAddress();
    resetCustomerDetailsAddress();
    resetCustomerDetailsCity();
    resetCustomerDetailsState();
    resetCustomerDetailsZip();
    resetSelectedPlansToDisplay();

    history.push(`/step-1${search}`);
  }, []);

  return <></>;
};

export default StartFresh;

import { AuthErrorCodes } from "firebase/auth";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useRecoilState } from "recoil";

import envelopeIcon from "../../assets/images/envelope-icon.svg";
import {
  getUser,
  reloadUser,
  sendUserEmailVerification,
  signUserOut,
} from "../../services/firebase";
import { signedInUserAtom } from "../../state/atoms/auth";
import { toastDataAtom } from "../../state/atoms/ui";
import { ToastType } from "../Toast";

const VerificationEmailSent: FunctionComponent<{}> = () => {
  const [signedInUser, setSignedInUser] = useRecoilState(signedInUserAtom);
  const [toastData, setToastData] = useRecoilState(toastDataAtom);
  const [verificationEmailSent, setVerificationEmailSent] = useState(false);

  const resendVerificationEmail = async () => {
    if (signedInUser) {
      try {
        const user = getUser();
        if (user) {
          await sendUserEmailVerification(user);
        }
        setVerificationEmailSent(true);
        setToastData([
          ...toastData,
          {
            toastType: ToastType.success,
            header: "We've resent you an email!",
          },
        ]);
      } catch (err) {
        const { code } = (err as { code: string }) || {};
        console.error(`Error: ${JSON.stringify(err)}`);
        switch (code) {
          case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
            setToastData([
              ...toastData,
              {
                toastType: ToastType.timer,
                header: "Too many attempts",
                body: "Please try again later",
              },
            ]);
            break;
          default:
            setToastData([
              ...toastData,
              {
                toastType: ToastType.alert,
                header: "Something went wrong",
              },
            ]);
            break;
        }
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      const updatedUser = await reloadUser();
      if (updatedUser?.emailVerified && signedInUser) {
        clearInterval(interval);
        setSignedInUser({
          ...signedInUser,
          emailVerified: true,
        });
      }
    }, 2500);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="container-sm text-center m-auto">
      <div
        className="card card-body mt-4 mb-2 p-4 rounded m-auto"
        style={{ width: "23.5rem" }}
      >
        <div className="m-auto rounded-circle bg-lighten-primary p-2 d-flex justify-content-center align-items-center">
          <img src={envelopeIcon} alt="" width="22px" />
        </div>
        <div className="mt-2">
          To activate your account and access
          <br />
          our proposal wizard, check your inbox!
        </div>
        {!verificationEmailSent ? (
          <div className="mt-2">
            Did not receive an email?
            <br />
            <div
              className="link"
              role="button"
              onClick={() => resendVerificationEmail()}
            >
              Resend verification email
            </div>
          </div>
        ) : (
          <div className="mt-2">
            We&apos;ve resent you an email!
            <div
              className="link"
              role="button"
              onClick={() => resendVerificationEmail()}
            >
              Resend again
            </div>
          </div>
        )}
      </div>
      <div
        className="link"
        role="button"
        onClick={() => signUserOut(setSignedInUser)}
      >
        Back to log in
      </div>
    </div>
  );
};

export default VerificationEmailSent;

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  UserCredential,
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  NextOrObserver,
  onAuthStateChanged,
  User,
  Unsubscribe as AuthUnsubscribe,
  sendEmailVerification,
  sendPasswordResetEmail,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  DocumentReference,
} from "firebase/firestore";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import { SetterOrUpdater } from "recoil";

import firebaseConfig, { baseURL } from "./firebaseServiceConfig";

import { Installer } from "../types/installers";
import { Proposal, UsaState } from "../types/proposal";
import { Deal } from "../types/form";
import { PlansData } from "../types/plans";
import { BatteryCost } from "../types/battery";
import { AppSettings, Role } from "../types/app-settings";
import { SalesRep, UID } from "../types/user";

// --------------------------------------------------------
// Init
// --------------------------------------------------------

export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);
export const firebaseAnalytics = getAnalytics(firebaseApp);
export const firebaseFirestore = getFirestore(firebaseApp);
export const firebaseFunctions = getFunctions(firebaseApp);

if (process.env.NODE_ENV === "development") {
  console.warn(`Running in ${process.env.NODE_ENV} environment`);
  // connectAuthEmulator(firebaseAuth, "http://localhost:9099");
  connectFunctionsEmulator(firebaseFunctions, "localhost", 5001);
  // connectFirestoreEmulator(firebaseFirestore, "localhost", 5002);
}

// --------------------------------------------------------
// Auth
// --------------------------------------------------------

export const signUserUp = (
  email: string,
  password: string
): Promise<UserCredential> =>
  createUserWithEmailAndPassword(firebaseAuth, email, password);

export const signUserIn = (
  email: string,
  password: string
): Promise<UserCredential> =>
  signInWithEmailAndPassword(firebaseAuth, email, password);

export const signUserOut = async (
  setSignedInUser: SetterOrUpdater<SalesRep | null>
): Promise<void> => {
  await signOut(firebaseAuth);
  setSignedInUser(null);
};

export const sendUserEmailVerification = (user: User): Promise<void> =>
  sendEmailVerification(user, {
    url: `${baseURL}/sign-in?showEmailVerifiedModal=true`,
  });

export const sendUserResetPassword = (email: string): Promise<void> =>
  sendPasswordResetEmail(firebaseAuth, email);

export const onAuthStateChangedHook = (
  observer: NextOrObserver<User>
): AuthUnsubscribe => onAuthStateChanged(firebaseAuth, observer);

// --------------------------------------------------------
// Firestore
// --------------------------------------------------------

const installersListCollection = collection(
  firebaseFirestore,
  "installersList"
);
const salesRepsCollection = collection(firebaseFirestore, "salesReps");
const statesCollection = collection(firebaseFirestore, "states");
const configurationCollection = collection(firebaseFirestore, "configuration");
const rolesCollection = collection(
  firebaseFirestore,
  "configuration/appSettings/roles"
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const removeUndefineds = (obj: any) => {
  Object.entries(obj).forEach(([key, val]) => {
    if (val && typeof val === "object") removeUndefineds(val);
    else if (val === null || val === undefined) delete obj[key];
  });
};

const setDocSafely = <T>(docRef: DocumentReference<T>, data: T) => {
  const sanitized = structuredClone(data) as T;
  removeUndefineds(sanitized);
  return setDoc(docRef, sanitized);
};

export const getSalesRepUser = async (uid: UID): Promise<SalesRep | null> => {
  const user = (
    await getDoc(doc(salesRepsCollection, uid))
  ).data() as SalesRep | null;
  return user || null;
};

export const upsertSalesRepUser = async (user: SalesRep): Promise<SalesRep> => {
  await setDocSafely(doc(salesRepsCollection, user.uid), user);
  return user;
};

export const updateSalesRepUser = async (
  uid: UID,
  partialUser: Partial<SalesRep>
): Promise<SalesRep | null> => {
  await updateDoc(doc(salesRepsCollection, uid), { ...partialUser });
  const user = await getSalesRepUser(uid);
  return user;
};

export const getInstallerFromList = async (
  id: string
): Promise<Installer | null> => {
  const installer = (
    await getDoc(doc(installersListCollection, id))
  ).data() as Installer | null;
  return installer;
};

const getRoles = async (): Promise<Role[]> => {
  const roles: Role[] = (await getDocs(rolesCollection)).docs.map((doc) => {
    const { name, enabled } = doc.data() as Role;
    return {
      id: doc.id,
      name,
      enabled,
    };
  });

  return roles;
};

export const getAppSettings = async (): Promise<AppSettings | null> => {
  const appSettings = {
    ...(await getDoc(doc(configurationCollection, "appSettings"))).data(),
    roles: await getRoles(),
  } as AppSettings | null;
  return appSettings;
};

export const getStates = async (): Promise<UsaState[] | null> => {
  const states: UsaState[] = (await getDocs(statesCollection)).docs.map(
    (doc) => {
      const { name, enabled, leaseEnabled } = doc.data() as UsaState;

      return {
        abbreviation: doc.id,
        name,
        enabled,
        leaseEnabled,
      };
    }
  );

  return states;
};

// --------------------------------------------------------
// Functions
// --------------------------------------------------------

const openInviteLeadFunction = httpsCallable(
  firebaseFunctions,
  "openInviteLead"
);
export const openInviteLead = async ({
  email,
  recaptchaToken,
}: {
  email: string;
  recaptchaToken: string;
}): Promise<{ result?: { message?: string }; status: number }> => {
  const { data } = await openInviteLeadFunction({ email, recaptchaToken });
  if (!data) {
    throw new Error("Error opening invite lead");
  }
  return data as { result?: { message?: string }; status: number };
};

const salesRepPostSignupFunction = httpsCallable(
  firebaseFunctions,
  "salesRepPostSignup"
);
export const salesRepPostSignup = async (): Promise<{
  result?: { isAdmin: boolean };
  status: number;
}> => {
  const { data } = await salesRepPostSignupFunction();
  if (!data) {
    throw new Error("Error signing up sales rep");
  }
  return data as { result?: { isAdmin: boolean }; status: number };
};

const getStripeConnectUrlFunction = httpsCallable(
  firebaseFunctions,
  "getStripeConnectUrl"
);
export const getStripeConnectUrl = async (): Promise<{
  result?: { stripeConnectUrl: string };
  status: number;
}> => {
  const { data } = await getStripeConnectUrlFunction();
  if (!data) {
    throw new Error("Error getting stripe connect url");
  }
  return data as { result?: { stripeConnectUrl: string }; status: number };
};

const sendDealProposalFunction = httpsCallable(
  firebaseFunctions,
  "sendDealProposal"
);
export const sendDealProposal = async (
  proposal: Proposal
): Promise<{ result?: { proposalId: string }; status: number }> => {
  const { data } = await sendDealProposalFunction({ proposal });
  if (!data) {
    throw new Error("Error sending proposal");
  }
  return data as { result?: { proposalId: string }; status: number };
};

const calculateBatteryFunction = httpsCallable(
  firebaseFunctions,
  "calculateBattery"
);
export const calculateBattery = async ({
  deal,
  installer,
}: {
  deal: Deal;
  installer: Installer;
}): Promise<{ result?: BatteryCost; status: number }> => {
  const { data } = await calculateBatteryFunction({
    proposal: { deal, installer },
  });
  if (!data) {
    throw new Error("Error calculating battery cost");
  }
  return data as { result?: BatteryCost; status: number };
};

const calculatePlanFunction = httpsCallable(firebaseFunctions, "calculatePlan");
export const calculatePlan = async ({
  deal,
  installer,
}: {
  deal: Deal;
  installer: Installer;
}): Promise<{ result?: PlansData; status: number }> => {
  const { data } = await calculatePlanFunction({
    proposal: { deal, installer },
  });
  if (!data) {
    throw new Error("Error calculating plans");
  }
  return data as { result?: PlansData; status: number };
};

export const getUser = (): User | null => {
  return firebaseAuth.currentUser;
};

export const reloadUser = async (): Promise<User | null> => {
  await firebaseAuth.currentUser?.reload();
  return firebaseAuth.currentUser;
};

const getFormulaParamFunction = httpsCallable(
  firebaseFunctions,
  "getFormulaParam"
);
export const getFormulaParam = async (param: string): Promise<number> => {
  const { data } = (await getFormulaParamFunction({ param })) as {
    data: { result?: { value: number }; status: number };
  };
  if (!data || data?.status !== 200) {
    throw new Error(`Error getting formula param ${param}`);
  }
  return data.result?.value || 0;
};

const migrateCurrentCustomersWithSunlightDataFunction = httpsCallable(
  firebaseFunctions,
  "migrateCurrentCustomersWithSunlightData"
);

export const migrateCustomerData = async (migrate: boolean) => {
  await migrateCurrentCustomersWithSunlightDataFunction({
    migrate,
  });
};

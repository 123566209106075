import { ToastType } from "../components/Toast";

export enum WindowBreakPoints {
  sm = 576,
  md = 768,
  lg = 992,
  xl = 1200,
  xxl = 1400,
}

export type ToastData = {
  header: string;
  body?: string;
  toastType: ToastType;
  timeout?: number;
};

export enum SubmissionStates {
  NONE = "NONE",
  SUBMITTING = "SUBMITTING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import CountUp from "react-countup";

import addIcon from "../../assets/images/add-icon.svg";
import removeIcon from "../../assets/images/remove-icon.svg";
import editIcon from "../../assets/images/edit-icon.svg";

import batteryIcon from "../../assets/images/battery.svg";

import planIndependent from "../../assets/images/plans/plan-v2-independent.svg";
import planSafeAndSound from "../../assets/images/plans/plan-v2-safe-and-sound.svg";
import planSelfMade from "../../assets/images/plans/plan-v2-self-made.svg";
import loader from "../../assets/images/loader-white.gif";
import { isInDemoMode, allPlansLocked } from "../../state/atoms/ui";
import {
  calculateBattery,
  calculatePlan,
  getFormulaParam,
} from "../../services/firebase";

import { Deal } from "../../types/form";
import { Installer } from "../../types/installers";
import { PlanSaving } from "../../types/plans";

import {
  calculatedPlansFixedAtom,
  calculatedPlansPayNowAtom,
  calculatedPlansPayLaterAtom,
  systemDetailsAdditionalBatteryTotalCapacityAtom,
  systemDetailsAdditionalBatteryTotalCostAtom,
  systemDetailsIsCalculatingBatteryCostAtom,
  systemDetailsAdditionalBatteryCalculatedMonthlyCostAtom,
  systemDetailsEstProductionAtom,
  systemDetailsHasAdditionalBatteryAtom,
  systemDetailsIsAddingBatteryAtom,
  systemDetailsPricePerWAtom,
  systemDetailsSystemSizeAtom,
  systemDetailsUtilityRateAtom,
  systemLocationAddressAtom,
  systemLocationCityAtom,
  systemLocationStateAtom,
  systemLocationZipAtom,
  calculatedPlansSavingDetailsPayNowAtom,
  calculatedPlansSavingDetailsFixedAtom,
  systemDetailsIsEditingPlanAtom,
  calculatedPlansSavingDetailsPayLaterAtom,
  calculatedLoanDetailsAtom,
  selectedPlansToDisplayAtom,
} from "../../state/atoms/form";
import {
  canSubmitStep2Selector,
  getPlansTooltipMessagesSelector,
  systemDetailsEstProductionErrorSelector,
  systemDetailsPricePerWErrorSelector,
  systemDetailsSystemSizeErrorSelector,
  systemDetailsUtilityRateErrorSelector,
} from "../../state/selectors/form";
import {
  appSettingsAtom,
  contextInstallerAtom,
  contextInstallerIdAtom,
  contextITCRateAtom,
  contextElectricityAccelerationAtom,
} from "../../state/atoms/global";

import { TagType } from "../Tag";
import { generateMockLoan } from "../../types/mock-data";
import PlanCard from "./PlanCard";

const Step2: FunctionComponent<{}> = () => {
  const { search } = useLocation();
  const history = useHistory();

  const { allowBattery, discountLockThreshold } =
    useRecoilValue(appSettingsAtom);
  enum Stages {
    SystemDetails,
    PlanConfirmation,
  }

  const demoMode = useRecoilValue(isInDemoMode);
  const [animation, setAnimation] = useState("");
  const isAllPlansLocked = useRecoilValue(allPlansLocked);
  const [isEditingBattery, setIsEditingBattery] = useState(false);
  const isEditingPlan = useRecoilValue(systemDetailsIsEditingPlanAtom);
  const [currentStage, setCurrentStage] = useState(Stages.SystemDetails);
  const [nextStage, setNextStage] = useState(Stages.SystemDetails);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const selectedPlansToDisplay = useRecoilValue(selectedPlansToDisplayAtom);
  const installerId = useRecoilValue(contextInstallerIdAtom);
  const installer = useRecoilValue(contextInstallerAtom);

  const systemLocationAddress = useRecoilValue(systemLocationAddressAtom);
  const systemLocationCity = useRecoilValue(systemLocationCityAtom);
  const systemLocationState = useRecoilValue(systemLocationStateAtom);
  const systemLocationZip = useRecoilValue(systemLocationZipAtom);
  const [systemDetailsIsAddingBattery, setSystemDetailsIsAddingBattery] =
    useRecoilState(systemDetailsIsAddingBatteryAtom);
  const [
    systemDetailsHasAdditionalBattery,
    setSystemDetailsHasAdditionalBattery,
  ] = useRecoilState(systemDetailsHasAdditionalBatteryAtom);
  const [
    systemDetailsAdditionalBatteryTotalCost,
    setSystemDetailsAdditionalBatteryTotalCost,
  ] = useRecoilState(systemDetailsAdditionalBatteryTotalCostAtom);
  const [
    systemDetailsAdditionalBatteryTotalCapacity,
    setSystemDetailsAdditionalBatteryTotalCapacity,
  ] = useRecoilState(systemDetailsAdditionalBatteryTotalCapacityAtom);
  const [
    systemDetailsIsCalculatingBatteryCost,
    setSystemDetailsIsCalculatingBatteryCost,
  ] = useRecoilState(systemDetailsIsCalculatingBatteryCostAtom);
  const [
    systemDetailsAdditionalBatteryCalculatedMonthlyCost,
    setSystemDetailsAdditionalBatteryCalculatedMonthlyCost,
  ] = useRecoilState(systemDetailsAdditionalBatteryCalculatedMonthlyCostAtom);

  const setITCRate = useSetRecoilState(contextITCRateAtom);
  const setElectricityAcceleration = useSetRecoilState(
    contextElectricityAccelerationAtom
  );

  useEffect(() => {
    async function fetchFormulaParams() {
      const [itcRate, electricityAcceleration] = await Promise.all([
        getFormulaParam("ITC_RATE"),
        getFormulaParam("YEARLY_INCREACE_IN_ELECTRICITY"),
      ]);

      setITCRate(itcRate);
      setElectricityAcceleration(electricityAcceleration);
    }
    fetchFormulaParams();
  }, []);

  const setCalculatedPlansFixed = useSetRecoilState(calculatedPlansFixedAtom);
  const setCalculatedPlansPayNow = useSetRecoilState(calculatedPlansPayNowAtom);
  const setCalculatedPlansPayLater = useSetRecoilState(
    calculatedPlansPayLaterAtom
  );
  const setCalculatedLoanDetails = useSetRecoilState(calculatedLoanDetailsAtom);

  const [
    calculatedPlansSavingDetailsFixed,
    setCalculatedPlansSavingDetailsFixed,
  ] = useRecoilState(calculatedPlansSavingDetailsFixedAtom);
  const [
    calculatedPlansSavingDetailsPayNow,
    setCalculatedPlansSavingDetailsPayNow,
  ] = useRecoilState(calculatedPlansSavingDetailsPayNowAtom);
  const setCalculatedPlansSavingDetailsPayLater = useSetRecoilState(
    calculatedPlansSavingDetailsPayLaterAtom
  );

  const canSubmitStep = useRecoilValue(canSubmitStep2Selector);

  const systemDetailsSystemSize = useRecoilValue(systemDetailsSystemSizeAtom);
  const systemDetailsEstProduction = useRecoilValue(
    systemDetailsEstProductionAtom
  );
  const systemDetailsPricePerW = useRecoilValue(systemDetailsPricePerWAtom);
  const systemDetailsUtilityRate = useRecoilValue(systemDetailsUtilityRateAtom);

  const animationIn = "rhino_animate__animated rhino_animate__fadeIn";
  const animationOut = "animate__animated animate__fadeOut";

  const hasAtLeastOnePlanSelected =
    selectedPlansToDisplay.buy ||
    selectedPlansToDisplay.lease ||
    selectedPlansToDisplay.loan;

  useEffect(() => {
    if (currentStage !== nextStage) {
      if (nextStage === Stages.PlanConfirmation) {
        setAnimation(animationOut);
        setTimeout(() => {
          setCurrentStage(nextStage);
          setAnimation(animationIn);
        }, 250);
      } else {
        setAnimation(animationOut);
        setTimeout(() => {
          setCurrentStage(nextStage);
          setAnimation(animationIn);
        }, 250);
      }
    }
  }, [nextStage]);

  if (!installerId || !installer?.name) {
    return <></>;
  }

  const handleCalculateBatteryCost = async () => {
    try {
      setSystemDetailsIsCalculatingBatteryCost(true);
      if (
        (systemDetailsAdditionalBatteryTotalCost || 0 > 0) &&
        (systemDetailsAdditionalBatteryTotalCapacity || 0 > 0)
      ) {
        const { result, status } = await calculateBattery({
          deal: {
            batteryDetails: {
              totalCost: systemDetailsAdditionalBatteryTotalCost || 0,
              totalCapacity: systemDetailsAdditionalBatteryTotalCapacity || 0,
            },
          },
          installer: {
            id: installerId,
            name: installer.name,
          },
        });
        if (status === 200 && typeof result?.monthly === "number") {
          setSystemDetailsAdditionalBatteryCalculatedMonthlyCost(
            result.monthly
          );
          setSystemDetailsIsAddingBattery(false);
          setIsEditingBattery(false);
        } else {
          console.error("Error calculating battery cost");
        }
      }
    } catch (err) {
      console.error("Error calculating battery cost", err);
    } finally {
      setSystemDetailsIsCalculatingBatteryCost(false);
    }
  };

  const handleCalculatePlan = async () => {
    try {
      setIsSubmitting(true);
      if (currentStage === Stages.SystemDetails) {
        if (canSubmitStep) {
          const { result, status } = await calculatePlan({
            deal: {
              systemDetails: {
                systemSize: systemDetailsSystemSize || 0,
                estProduction: systemDetailsEstProduction || 0,
                pricePerW: systemDetailsPricePerW || 0,
                utilityRate: systemDetailsUtilityRate || 0,
              },
              systemLocation: {
                address: systemLocationAddress || "",
                city: systemLocationCity || "",
                state: systemLocationState || "",
                zip: systemLocationZip || "",
              },
            },
            installer: {
              id: installerId,
              name: installer.name,
            },
          });

          if (status === 200 && result) {
            const {
              calculatedPlans: { payNow, fixed, payLater },
              savingDetails: {
                payNow: savingDetailsPayNow,
                fixed: savingDetailsFixed,
                payLater: savingDetailsPayLater,
              },
              loan,
            } = result;

            setCalculatedPlansFixed(fixed);
            setCalculatedPlansPayNow(payNow);
            setCalculatedPlansPayLater(payLater);

            setCalculatedPlansSavingDetailsFixed(savingDetailsFixed);
            setCalculatedPlansSavingDetailsPayNow(savingDetailsPayNow);
            setCalculatedPlansSavingDetailsPayLater(savingDetailsPayLater);

            if (demoMode) {
              const mockLoan = generateMockLoan({
                leaseMonthlyPayment: fixed,
                leaseSaving: savingDetailsFixed,
              });
              setCalculatedLoanDetails(mockLoan);
            } else {
              setCalculatedLoanDetails(loan);
            }

            setNextStage(Stages.PlanConfirmation);
          } else {
            console.error("Error calculating plan");
          }
          window.scrollTo(0, 0);
        }
      } else {
        history.push(`/step-3${search}`);
      }
    } catch (err) {
      console.error("Error calculating plan", err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        handleCalculatePlan();
      }}
    >
      <h1>Create a Proposal</h1>
      <div className="mt-3">
        <div className={animation}>
          {currentStage === Stages.SystemDetails ? (
            <SystemDetailsForm />
          ) : (
            <PlanConfirmationsForm />
          )}
        </div>
      </div>
      <div className="max-w-700px m-auto mb-8 row text-start">
        {allowBattery && (
          <>
            <hr className="my-3" />
            <div className="col-12 p-0">
              {!systemDetailsHasAdditionalBattery ? (
                <div
                  key="add-battery-container"
                  className="rhino_animate__animated rhino_animate__fadeIn"
                >
                  <div
                    className="cursor-pointer user-select-none font-weight-600"
                    role="button"
                    onClick={() => {
                      setSystemDetailsHasAdditionalBattery(true);
                      setSystemDetailsIsAddingBattery(true);
                    }}
                  >
                    <img src={addIcon} alt="Add battery" />
                    <span className="ml-1">Add battery</span>
                  </div>
                </div>
              ) : (
                <div
                  key="remove-battery-container"
                  className="rhino_animate__animated rhino_animate__fadeIn"
                >
                  <div>
                    <span
                      className="cursor-pointer user-select-none text-muted text-small"
                      role="button"
                      onClick={() => {
                        setSystemDetailsHasAdditionalBattery(false);
                        setSystemDetailsIsAddingBattery(false);
                      }}
                    >
                      <img src={removeIcon} alt="Remove battery" width="18px" />
                      <span className="ml-1">Remove battery</span>
                    </span>
                    {!systemDetailsIsAddingBattery && isEditingBattery && (
                      <button
                        className="btn btn-tertiary btn-xs float-right"
                        onClick={handleCalculateBatteryCost}
                        disabled={
                          systemDetailsIsCalculatingBatteryCost ||
                          !canSubmitStep
                        }
                      >
                        Calculate
                      </button>
                    )}
                  </div>
                  <div className="position-relative">
                    <div className="row mt-4 mb-2">
                      <div className="col-12">
                        <div className="d-flex justify-content-between">
                          <div>
                            <h5 className="mb-3 d-inline-block">
                              Battery details
                            </h5>
                            {!systemDetailsIsAddingBattery &&
                              !isEditingBattery && (
                                <div
                                  className="d-inline-block cursor-pointer"
                                  role="button"
                                  onClick={() => setIsEditingBattery(true)}
                                >
                                  <img
                                    src={editIcon}
                                    alt="Edit"
                                    className="ml-2 mr-05"
                                  />
                                  <small className="text-muted d-inline-block">
                                    Edit
                                  </small>
                                </div>
                              )}
                          </div>
                          {systemDetailsIsAddingBattery && (
                            <button
                              className="btn btn-tertiary btn-xs"
                              onClick={handleCalculateBatteryCost}
                              disabled={systemDetailsIsCalculatingBatteryCost}
                            >
                              Calculate
                            </button>
                          )}
                        </div>
                      </div>
                      {systemDetailsIsAddingBattery ? (
                        <>
                          <div className="form-floating col-12 col-md-6">
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Total cost ($)"
                              id="battery-total-cost"
                              autoComplete="off"
                              value={
                                systemDetailsAdditionalBatteryTotalCost || ""
                              }
                              onChange={(e) =>
                                setSystemDetailsAdditionalBatteryTotalCost(
                                  parseFloat(e.target.value)
                                )
                              }
                            />
                            <label htmlFor="battery-total-cost">
                              Total cost ($)
                            </label>
                          </div>
                          <div className="form-floating col-12 col-md-6 mt-2 mt-md-0">
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Total capacity (kW)"
                              id="battery-total-capacity"
                              autoComplete="off"
                              value={
                                systemDetailsAdditionalBatteryTotalCapacity ||
                                ""
                              }
                              onChange={(e) =>
                                setSystemDetailsAdditionalBatteryTotalCapacity(
                                  parseFloat(e.target.value)
                                )
                              }
                            />
                            <label htmlFor="battery-total-capacity">
                              Total capacity (kW)
                            </label>
                          </div>
                        </>
                      ) : (
                        <div>
                          <div className="row justify-content-start mt-1">
                            <div className="plan-container col-6 col-md-4 pr-4">
                              <div className="text-primary">Battery cost</div>
                              <div>
                                {!isEditingBattery ? (
                                  <>
                                    <h4>$</h4>
                                    <h2>
                                      {systemDetailsAdditionalBatteryTotalCost?.toLocaleString(
                                        "en",
                                        {
                                          useGrouping: true,
                                        }
                                      )}
                                    </h2>
                                  </>
                                ) : (
                                  <input
                                    type="number"
                                    className="inline-form-control"
                                    id="battery-total-cost"
                                    autoComplete="off"
                                    value={
                                      systemDetailsAdditionalBatteryTotalCost ||
                                      ""
                                    }
                                    onChange={(e) =>
                                      setSystemDetailsAdditionalBatteryTotalCost(
                                        parseFloat(e.target.value)
                                      )
                                    }
                                  />
                                )}
                              </div>
                            </div>
                            <div className="plan-container col-6 col-md-4">
                              <div className="text-primary">
                                Battery capacity
                              </div>
                              <div>
                                {!isEditingBattery ? (
                                  <>
                                    <h2>
                                      {systemDetailsAdditionalBatteryTotalCapacity?.toLocaleString(
                                        "en",
                                        {
                                          useGrouping: true,
                                        }
                                      )}
                                    </h2>
                                    <h4>kW</h4>
                                  </>
                                ) : (
                                  <input
                                    type="number"
                                    className="inline-form-control"
                                    id="battery-total-capacity"
                                    autoComplete="off"
                                    value={
                                      systemDetailsAdditionalBatteryTotalCapacity ||
                                      ""
                                    }
                                    onChange={(e) =>
                                      setSystemDetailsAdditionalBatteryTotalCapacity(
                                        parseFloat(e.target.value)
                                      )
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="battery-container border border-muted bg-white py-2 mt-4 mt-md-0">
                            <div className="row px-4">
                              <div className="col p-0 pr-2">
                                <small>
                                  <div>Battery</div>
                                  <div className="no-wrap">monthly cost</div>
                                </small>
                                <div>
                                  <h4>$</h4>
                                  <h2>
                                    <CountUp
                                      end={
                                        systemDetailsAdditionalBatteryCalculatedMonthlyCost ||
                                        0
                                      }
                                      duration={1}
                                      separator=","
                                      decimal="."
                                    />
                                  </h2>
                                </div>
                              </div>
                              <div className="col p-0 pl-2 text-right">
                                <img
                                  src={batteryIcon}
                                  alt="Battery"
                                  className="h-100"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        <div className="col-12 mt-3 p-0">
          <button
            type="submit"
            className="btn btn-primary w-100 font-weight-600"
            disabled={
              (currentStage === Stages.PlanConfirmation &&
                !hasAtLeastOnePlanSelected) ||
              (isAllPlansLocked && currentStage === Stages.PlanConfirmation) ||
              !canSubmitStep ||
              isEditingPlan ||
              isSubmitting ||
              (currentStage === Stages.PlanConfirmation &&
                calculatedPlansSavingDetailsFixed.percentage <
                  discountLockThreshold &&
                calculatedPlansSavingDetailsPayNow.percentage <
                  discountLockThreshold)
            }
          >
            {isSubmitting ? (
              <img height="22px" src={loader} alt="Submitting..." />
            ) : (
              "Continue"
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

const SystemDetailsForm: FunctionComponent<{}> = () => {
  const [systemDetailsSystemSize, setSystemDetailsSystemSize] = useRecoilState(
    systemDetailsSystemSizeAtom
  );
  const [systemDetailsEstProduction, setSystemDetailsEstProduction] =
    useRecoilState(systemDetailsEstProductionAtom);
  const [systemDetailsPricePerW, setSystemDetailsPricePerW] = useRecoilState(
    systemDetailsPricePerWAtom
  );
  const [systemDetailsUtilityRate, setSystemDetailsUtilityRate] =
    useRecoilState(systemDetailsUtilityRateAtom);

  const systemDetailsSystemSizeError = useRecoilValue(
    systemDetailsSystemSizeErrorSelector
  );
  const systemDetailsEstProductionError = useRecoilValue(
    systemDetailsEstProductionErrorSelector
  );
  const systemDetailsPricePerWError = useRecoilValue(
    systemDetailsPricePerWErrorSelector
  );
  const systemDetailsUtilityRateError = useRecoilValue(
    systemDetailsUtilityRateErrorSelector
  );

  const [systemDetailsSystemSizeBlurred, setSystemDetailsSystemSizeBlurred] =
    useState(false);
  const [
    systemDetailsEstProductionBlurred,
    setSystemDetailsEstProductionBlurred,
  ] = useState(false);
  const [systemDetailsPricePerWBlurred, setSystemDetailsPricePerWBlurred] =
    useState(false);
  const [systemDetailsUtilityRateBlurred, setSystemDetailsUtilityRateBlurred] =
    useState(false);

  return (
    <div className="form-group max-w-700px m-auto text-start">
      <h5>System details</h5>
      <div className="row">
        <div className="form-floating col-12 col-md-6">
          <input
            type="number"
            className="form-control data-hj-allow"
            placeholder="System size (kW)"
            id="system-size"
            autoComplete="off"
            value={systemDetailsSystemSize || ""}
            onChange={(e) => {
              setSystemDetailsSystemSizeBlurred(false);
              setSystemDetailsSystemSize(parseFloat(e.target.value) || 0);
            }}
            onBlur={() => setSystemDetailsSystemSizeBlurred(true)}
          />
          <label htmlFor="system-size">System size (kW)</label>
          {systemDetailsSystemSizeBlurred && systemDetailsSystemSizeError && (
            <div className="pl-1 text-primary text-small">
              {systemDetailsSystemSizeError}
            </div>
          )}
        </div>
        <div className="form-floating col-12 col-md-6 mt-3 mt-md-0">
          <input
            type="number"
            className="form-control data-hj-allow"
            placeholder="Est. production (kWh)"
            id="est-production"
            autoComplete="off"
            value={systemDetailsEstProduction || ""}
            onChange={(e) => {
              setSystemDetailsEstProductionBlurred(false);
              setSystemDetailsEstProduction(parseFloat(e.target.value));
            }}
            onBlur={() => setSystemDetailsEstProductionBlurred(true)}
          />
          <label htmlFor="est-production">Est. production (kWh)</label>
          {systemDetailsEstProductionBlurred &&
            systemDetailsEstProductionError && (
              <div className="pl-1 text-primary text-small">
                {systemDetailsEstProductionError}
              </div>
            )}
        </div>
        <div className="form-floating col-12 col-lg mt-3">
          <input
            type="number"
            className="form-control data-hj-allow"
            placeholder="Price per W ($)"
            id="price-per-w"
            autoComplete="off"
            value={systemDetailsPricePerW || ""}
            onChange={(e) => {
              setSystemDetailsPricePerWBlurred(false);
              setSystemDetailsPricePerW(parseFloat(e.target.value));
            }}
            onBlur={() => setSystemDetailsPricePerWBlurred(true)}
          />
          <label htmlFor="price-per-w">Price per W ($)</label>
          {systemDetailsPricePerWBlurred && systemDetailsPricePerWError && (
            <div className="pl-1 text-primary text-small">
              {systemDetailsPricePerWError}
            </div>
          )}
        </div>
        <div className="form-floating col-12 col-lg mt-3">
          <input
            type="number"
            className="form-control data-hj-allow"
            placeholder="Current utility rate (¢)"
            id="utility-rate"
            autoComplete="off"
            value={systemDetailsUtilityRate || ""}
            onChange={(e) => {
              setSystemDetailsUtilityRateBlurred(false);
              setSystemDetailsUtilityRate(parseFloat(e.target.value));
            }}
            onBlur={() => setSystemDetailsUtilityRateBlurred(true)}
          />
          <label htmlFor="utility-rate">Current utility rate (¢)</label>
          {systemDetailsUtilityRateBlurred && systemDetailsUtilityRateError && (
            <div className="pl-1 text-primary text-small">
              {systemDetailsUtilityRateError}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const PlanConfirmationsForm: FunctionComponent<{}> = () => {
  const contextInstaller = useRecoilValue(contextInstallerAtom);
  const [isCalculating, setIsCalculating] = useState(false);
  const [isEditingPlan, setIsEditingPlan] = useRecoilState(
    systemDetailsIsEditingPlanAtom
  );

  const installerId = useRecoilValue(contextInstallerIdAtom);
  const installer = useRecoilValue(contextInstallerAtom);

  const systemLocationAddress = useRecoilValue(systemLocationAddressAtom);
  const systemLocationCity = useRecoilValue(systemLocationCityAtom);
  const systemLocationState = useRecoilValue(systemLocationStateAtom);
  const systemLocationZip = useRecoilValue(systemLocationZipAtom);

  const [systemDetailsSystemSize, setSystemDetailsSystemSize] = useRecoilState(
    systemDetailsSystemSizeAtom
  );
  const [systemDetailsEstProduction, setSystemDetailsEstProduction] =
    useRecoilState(systemDetailsEstProductionAtom);
  const [systemDetailsPricePerW, setSystemDetailsPricePerW] = useRecoilState(
    systemDetailsPricePerWAtom
  );
  const [systemDetailsUtilityRate, setSystemDetailsUtilityRate] =
    useRecoilState(systemDetailsUtilityRateAtom);

  const systemDetailsSystemSizeError = useRecoilValue(
    systemDetailsSystemSizeErrorSelector
  );
  const systemDetailsEstProductionError = useRecoilValue(
    systemDetailsEstProductionErrorSelector
  );
  const systemDetailsPricePerWError = useRecoilValue(
    systemDetailsPricePerWErrorSelector
  );
  const systemDetailsUtilityRateError = useRecoilValue(
    systemDetailsUtilityRateErrorSelector
  );

  const [systemDetailsSystemSizeBlurred, setSystemDetailsSystemSizeBlurred] =
    useState(false);
  const [
    systemDetailsEstProductionBlurred,
    setSystemDetailsEstProductionBlurred,
  ] = useState(false);
  const [systemDetailsPricePerWBlurred, setSystemDetailsPricePerWBlurred] =
    useState(false);
  const [systemDetailsUtilityRateBlurred, setSystemDetailsUtilityRateBlurred] =
    useState(false);

  const calculatedPlansFixed = parseFloat(
    (useRecoilValue(calculatedPlansFixedAtom) || 0).toFixed(2)
  );
  const calculatedPlansPayNow = parseFloat(
    (useRecoilValue(calculatedPlansPayNowAtom) || 0).toFixed(2)
  );

  const plansTooltipMessages = useRecoilValue(getPlansTooltipMessagesSelector);

  const setCalculatedPlansFixed = useSetRecoilState(calculatedPlansFixedAtom);
  const setCalculatedPlansPayNow = useSetRecoilState(calculatedPlansPayNowAtom);
  const setCalculatedPlansPayLater = useSetRecoilState(
    calculatedPlansPayLaterAtom
  );
  const [
    calculatedPlansSavingDetailsFixed,
    setCalculatedPlansSavingDetailsFixed,
  ] = useRecoilState(calculatedPlansSavingDetailsFixedAtom);
  const [
    calculatedPlansSavingDetailsPayNow,
    setCalculatedPlansSavingDetailsPayNow,
  ] = useRecoilState(calculatedPlansSavingDetailsPayNowAtom);
  const setCalculatedPlansSavingDetailsPayLater = useSetRecoilState(
    calculatedPlansSavingDetailsPayLaterAtom
  );
  const [calculatedLoanDetails, setCalculatedLoanDetails] = useRecoilState(
    calculatedLoanDetailsAtom
  );

  const canSubmitStep = useRecoilValue(canSubmitStep2Selector);

  const disclaimer = "Preview for qualified customers only";

  if (!installerId || !installer?.name) {
    return <></>;
  }

  const handleCalculatePlan = async () => {
    try {
      setIsCalculating(true);
      if (canSubmitStep) {
        const partialProposal: { deal: Deal; installer: Installer } = {
          deal: {
            systemDetails: {
              systemSize: systemDetailsSystemSize || 0,
              estProduction: systemDetailsEstProduction || 0,
              pricePerW: systemDetailsPricePerW || 0,
              utilityRate: systemDetailsUtilityRate || 0,
            },
            systemLocation: {
              address: systemLocationAddress || "",
              city: systemLocationCity || "",
              state: systemLocationState || "",
              zip: systemLocationZip || "",
            },
          },
          installer: {
            id: installerId,
            name: installer.name,
          },
        };
        const { result, status } = await calculatePlan(partialProposal);
        if (status === 200 && result) {
          const {
            calculatedPlans: { payNow, fixed, payLater },
            savingDetails: {
              payNow: savingDetailsPayNow,
              fixed: savingDetailsFixed,
              payLater: savingDetailsPayLater,
            },
            loan,
          } = result;

          setCalculatedPlansPayNow(payNow);
          setCalculatedPlansFixed(fixed);
          setCalculatedPlansPayLater(payLater);

          setCalculatedPlansSavingDetailsPayNow(savingDetailsPayNow);
          setCalculatedPlansSavingDetailsFixed(savingDetailsFixed);
          setCalculatedPlansSavingDetailsPayLater(savingDetailsPayLater);
          setCalculatedLoanDetails(loan);

          setIsEditingPlan(false);
        } else {
          console.error("Error calculating plan");
        }
      }
    } catch (err) {
      console.error("Error calculating plan", err);
    } finally {
      setIsCalculating(false);
    }
  };

  const plans: {
    imgSrc: string;
    title: string;
    tagType: TagType;
    savingDetails?: PlanSaving;
    details: React.ReactNode;
    tooltip: string;
  }[] = [
    {
      imgSrc: planIndependent,
      title: "Independent",
      tagType: TagType.buy,
      savingDetails: calculatedPlansSavingDetailsPayNow,
      tooltip: plansTooltipMessages.buy,
      details: (
        <>
          <h4>$</h4>
          <h2 className="data-hj-allow">
            <CountUp
              end={calculatedPlansPayNow}
              duration={1}
              separator=","
              decimal="."
            />
          </h2>
        </>
      ),
    },
    {
      imgSrc: planSelfMade,
      title: "Self Made",
      tagType: TagType.loan,
      savingDetails: {
        lifeTimeSavings: calculatedLoanDetails?.saving.lifeTimeSavings || 0,
        // TODO: percentage: calculatedLoanDetails?.saving.percentage || 0,
        percentage: 100,
      },
      tooltip: plansTooltipMessages.loan,
      details: (
        <div>
          <h4>$</h4>
          <h2 className="data-hj-allow">
            <CountUp
              end={calculatedLoanDetails?.monthlyPayment || 0}
              duration={1}
              separator=","
              decimal="."
            />
          </h2>
        </div>
      ),
    },
    {
      imgSrc: planSafeAndSound,
      title: "Safe & Sound",
      tagType: TagType.lease,
      savingDetails: calculatedPlansSavingDetailsFixed,
      tooltip: plansTooltipMessages.lease,
      details: (
        <>
          <h4>$</h4>
          <h2 className="data-hj-allow">
            <CountUp
              end={calculatedPlansFixed}
              duration={1}
              separator=","
              decimal="."
            />
          </h2>
        </>
      ),
    },
  ];

  if (!contextInstaller) {
    return <></>;
  }

  const { stripeAccountStatus, sunlightAccountStatus } = contextInstaller;

  return (
    <>
      <div className="max-w-700px m-auto mt-4 text-start">
        <div className="d-block d-md-none mt-2">
          <div className="col-12 text-center border border-muted plan-disclaimer">
            {disclaimer}
          </div>
        </div>
        <div className="row py-0 py-md-2 px-2 m-0 mt-0 mt-md-2 mb-4 mb-md-0 border border-muted plan-container">
          {plans.map(
            ({ imgSrc, title, tagType, details, savingDetails, tooltip }) => (
              <PlanCard
                key={`plan-${tagType}`}
                imgSrc={imgSrc}
                title={title}
                tagType={tagType}
                details={details}
                savingDetails={savingDetails}
                stripeAccountStatus={stripeAccountStatus}
                sunlightAccountStatus={sunlightAccountStatus}
                installer={installer}
                tooltip={tooltip}
              />
            )
          )}
        </div>
        <div className="d-none d-md-block mb-4 border border-muted plan-disclaimer">
          <div className="col-12 text-center">{disclaimer}</div>
        </div>
        <h5 className="mb-2 d-inline-block">System details</h5>
        {!isEditingPlan ? (
          <div
            className="d-inline-block cursor-pointer"
            role="button"
            onClick={() => {
              setIsEditingPlan(true);
            }}
          >
            <img src={editIcon} alt="Edit" className="ml-2 mr-05" />
            <small className="text-muted d-inline-block">Edit</small>
          </div>
        ) : (
          <button
            className="btn btn-tertiary btn-xs float-right"
            onClick={handleCalculatePlan}
            disabled={!canSubmitStep || isCalculating}
          >
            Calculate
          </button>
        )}
        <div className="row justify-content-between mt-1">
          <div className="plan-container col-6 col-md-3 pr-2">
            <div className="text-primary">System size</div>
            <div>
              {!isEditingPlan ? (
                <>
                  <h2 className="data-hj-allow">
                    {systemDetailsSystemSize?.toLocaleString("en", {
                      useGrouping: true,
                    })}
                  </h2>
                  <h4>kW</h4>
                </>
              ) : (
                <span className="inline-form-control-wrap">
                  <input
                    type="number"
                    className="inline-form-control data-hj-allow"
                    id="system-size"
                    autoComplete="off"
                    value={systemDetailsSystemSize || ""}
                    onChange={(e) => {
                      setSystemDetailsSystemSizeBlurred(false);
                      setSystemDetailsSystemSize(parseFloat(e.target.value));
                    }}
                    onBlur={() => {
                      setSystemDetailsSystemSizeBlurred(true);
                    }}
                  />
                </span>
              )}
              {systemDetailsSystemSizeBlurred &&
                systemDetailsSystemSizeError && (
                  <div className="text-primary text-small">
                    {systemDetailsSystemSizeError}
                  </div>
                )}
            </div>
          </div>
          <div className="plan-container col-6 col-md-3 pr-2">
            <div className="text-primary">Est. production</div>
            <div>
              {!isEditingPlan ? (
                <>
                  <h2 className="data-hj-allow">
                    {systemDetailsEstProduction?.toLocaleString("en", {
                      useGrouping: true,
                    })}
                  </h2>
                  <h4>kWh</h4>
                </>
              ) : (
                <span className="inline-form-control-wrap">
                  <input
                    type="number"
                    className="inline-form-control data-hj-allow"
                    id="est-production"
                    autoComplete="off"
                    value={systemDetailsEstProduction || ""}
                    onChange={(e) => {
                      setSystemDetailsEstProductionBlurred(false);
                      setSystemDetailsEstProduction(parseFloat(e.target.value));
                    }}
                    onBlur={() => {
                      setSystemDetailsEstProductionBlurred(true);
                    }}
                  />
                </span>
              )}
              {systemDetailsEstProductionBlurred &&
                systemDetailsEstProductionError && (
                  <div className="text-primary text-small">
                    {systemDetailsEstProductionError}
                  </div>
                )}
            </div>
          </div>
          <div className="plan-container col-6 col-md-3 pt-2 pt-md-0 pr-2">
            <div className="text-primary">Price per W</div>
            <div>
              {!isEditingPlan ? (
                <>
                  <h4>$</h4>
                  <h2 className="data-hj-allow">
                    {systemDetailsPricePerW?.toLocaleString("en", {
                      useGrouping: true,
                    })}
                  </h2>
                </>
              ) : (
                <span className="inline-form-control-wrap">
                  <input
                    type="number"
                    className="inline-form-control data-hj-allow"
                    autoComplete="off"
                    id="price-per-w"
                    value={systemDetailsPricePerW || ""}
                    onChange={(e) => {
                      setSystemDetailsPricePerWBlurred(false);
                      setSystemDetailsPricePerW(parseFloat(e.target.value));
                    }}
                    onBlur={() => {
                      setSystemDetailsPricePerWBlurred(true);
                    }}
                  />
                </span>
              )}
              {systemDetailsPricePerWBlurred && systemDetailsPricePerWError && (
                <div className="text-primary text-small">
                  {systemDetailsPricePerWError}
                </div>
              )}
            </div>
          </div>
          <div className="plan-container col-6 col-md-3 pt-2 pt-md-0">
            <div className="text-primary">Current utility rate</div>
            <div>
              {!isEditingPlan ? (
                <>
                  <h4>¢</h4>
                  <h2 className="data-hj-allow">
                    {systemDetailsUtilityRate?.toLocaleString("en", {
                      useGrouping: true,
                    })}
                  </h2>
                </>
              ) : (
                <span className="inline-form-control-wrap">
                  <input
                    type="number"
                    className="inline-form-control data-hj-allow"
                    id="utility-rate"
                    autoComplete="off"
                    value={systemDetailsUtilityRate || ""}
                    onChange={(e) => {
                      setSystemDetailsUtilityRateBlurred(false);
                      setSystemDetailsUtilityRate(parseFloat(e.target.value));
                    }}
                    onBlur={() => {
                      setSystemDetailsUtilityRateBlurred(true);
                    }}
                  />
                </span>
              )}
              {systemDetailsUtilityRateBlurred &&
                systemDetailsUtilityRateError && (
                  <div className="text-primary text-small">
                    {systemDetailsUtilityRateError}
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step2;

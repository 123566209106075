import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import stepImg from "../../assets/images/step.svg";
import stepCurrentImg from "../../assets/images/step-current.svg";

import { Step } from "../../types/steps";
import { useRecoilValue } from "recoil";
import {
  canSubmitStep1Selector,
  canSubmitStep2Selector,
} from "../../state/selectors/form";

const Stepper: FunctionComponent<{}> = () => {
  const { pathname, search } = useLocation();
  const params: { stepId: string } = useParams();
  const history = useHistory();

  const stepId = parseInt(params.stepId, 10);

  const canSubmitStep1 = useRecoilValue(canSubmitStep1Selector);
  const canSubmitStep2 = useRecoilValue(canSubmitStep2Selector);

  const [currentStep, setCurrentStep] = useState(stepId);

  const getStepMeta = (index: number): { className: string; img: string } => {
    if (stepId === index + 1) {
      return {
        className: "step step-current animate__animated animate__pulse",
        img: stepImg,
      };
    }
    return { className: "step", img: stepCurrentImg };
  };

  const steps: Step[] = [
    { title: "Step 1" },
    { title: "Step 2" },
    { title: "Step 3" },
  ];

  useEffect(() => {
    if (pathname !== `/step-${currentStep}`) {
      history.push(`/step-1${search}`);
    }

    if (
      (currentStep > 1 && !canSubmitStep1) ||
      (currentStep > 2 && !canSubmitStep2)
    ) {
      history.push(`/step-1${search}`);
    }
  }, [currentStep]);

  return (
    <section id="section-stepper" className="mb-0">
      <div className="stepper-container p-0 container-md text-center">
        {stepId > 1 && (
          <button
            className={`back-btn btn btn-sm btn-outline-primary py-025 pl-1 ${
              stepId === 1 ? "grayscale-1 opacity-50" : ""
            }`}
            onClick={() => {
              const stepIdToNavigateTo = stepId - 1 || 1;
              setCurrentStep(stepIdToNavigateTo);
              history.push(`/step-${stepIdToNavigateTo}${search}`);
            }}
          >
            <span>
              <span className="mr-05" style={{ marginTop: "-2px" }}>
                <svg
                  width="7"
                  height="8"
                  viewBox="0 0 7 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.603895 3.92009C0.335497 3.76513 0.335498 3.37773 0.603896 3.22277L6.03896 0.0848324C6.30736 -0.0701268 6.64286 0.123573 6.64286 0.433492L6.64286 6.70937C6.64286 7.01928 6.30736 7.21298 6.03896 7.05802L0.603895 3.92009Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
              Back
            </span>
          </button>
        )}
        <hr />
        <div className="steps">
          {steps.map(({ title }, index) => {
            const { className, img } = getStepMeta(index);
            return (
              <div key={`stepper-step-${index}`} className={className}>
                <img src={img} alt={title} className="m-0 p-0" />
                <div className="mt-1">{title}</div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Stepper;

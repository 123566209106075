const reCaptchaSiteKey = "6LdmA_EgAAAAAAq2RiHT0crgVkPgcXDIkULrUDXt";
const prodConfig = {
  apiKey: "AIzaSyBM3o52h5dQLdMYVAI_GdeGLRE5Ki3L3AM",
  authDomain: "posf-fe773.firebaseapp.com",
  projectId: "posf-fe773",
  storageBucket: "posf-fe773.appspot.com",
  messagingSenderId: "529187572892",
  appId: "1:529187572892:web:70d4e20a363552786ef159",
  measurementId: "G-KT5WD2TJFP",
};

const stagingConfig = {
  apiKey: "AIzaSyCRO4h4fJgL6uVM2LQLQjOEeYyEd_zsTnI",
  authDomain: "posf-test.firebaseapp.com",
  projectId: "posf-test",
  storageBucket: "posf-test.appspot.com",
  messagingSenderId: "518954640251",
  appId: "1:518954640251:web:6f9b809b713cd1b7a013de",
  measurementId: "G-NVKQ6Z5PY3",
};

const demoConfig = {
  apiKey: "AIzaSyBudtpnnDa84uTPGIQxlqllfsm2Sblphvc",
  authDomain: "posf-demo.firebaseapp.com",
  projectId: "posf-demo",
  storageBucket: "posf-demo.appspot.com",
  messagingSenderId: "823140616957",
  appId: "1:823140616957:web:a51ce2e0181d0089d68c94",
  measurementId: "G-TM3HG8Z6W5",
};

let config: {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
} = stagingConfig;

let baseURL = "https://installers-test.web.app";

switch (process.env.REACT_APP_FIREBASE_ENV) {
  case "PRODUCTION":
    config = prodConfig;
    baseURL = "https://partners.rhino-eco.com";
    break;
  case "DEMO":
    config = demoConfig;
    baseURL = "https://installers-posf-demo.web.app";
    break;
  default:
    break;
}

export default config;
export { baseURL, reCaptchaSiteKey };

import React, { FunctionComponent, useState } from "react";
import { useRecoilState } from "recoil";
import { Link, useHistory, useLocation } from "react-router-dom";

import { signUserOut } from "../../services/firebase";
import logo from "../../assets/images/logo.svg";
import profileIcon from "../../assets/images/profile-icon.svg";
import { signedInUserAtom } from "../../state/atoms/auth";

const Header: FunctionComponent<{}> = () => {
  const { search } = useLocation();
  const history = useHistory();

  const [signedInUser, setSignedInUser] = useRecoilState(signedInUserAtom);
  const [showDropdown, setShowDropdown] = useState(false);

  const name = [signedInUser?.firstName, signedInUser?.lastName].flatMap(
    (x) => x || []
  );

  const { emailVerified, profileUpdated } = signedInUser || {};

  return (
    <section id="section-header" className="my-2">
      <div className="row">
        <div className="col-6">
          <Link to={`/step-1${search}`}>
            <img className="logo" src={logo} alt="Rhino-Eco" />
          </Link>
        </div>
        <div className="col-6 mt-1 d-flex justify-content-end">
          {emailVerified && profileUpdated && (
            <div className="dropdown">
              <div
                className="user-select-none d-flex align-items-center"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded={showDropdown ? "true" : "false"}
                onClick={() => setShowDropdown(!showDropdown)}
              >
                <img src={profileIcon} className="mr-1" alt="" width="22px" />
                <span className="d-none d-md-block font-weight-500 pt-05">
                  {name.length > 0 ? name.join(" ") : "Anonymous user"}
                </span>
              </div>

              <div
                className={`dropdown-menu border-0 rounded rhino-shadow p-0 ${
                  showDropdown ? "show" : ""
                }`}
                style={{ right: 0, top: "2.5rem" }}
                aria-labelledby="dropdownMenuLink"
              >
                <div
                  className="dropdown-item rounded p-2 cursor-pointer"
                  role="button"
                  onClick={() => {
                    setShowDropdown(false);
                    history.push(`/my-account${search}`);
                  }}
                >
                  My profile
                </div>
                <div
                  className="dropdown-item rounded p-2 cursor-pointer"
                  role="button"
                  onClick={() => {
                    setShowDropdown(false);
                    signUserOut(setSignedInUser);
                  }}
                >
                  Log out
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Header;

import React, { useEffect } from "react";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Tooltip } from "react-tippy";

import alertIcon from "../../assets/images/alert-icon.svg";
import lockIcon from "../../assets/images/lock-icon.svg";

import { isInDemoMode, allPlansLocked } from "../../state/atoms/ui";

import {
  Installer,
  STRIPE_ACCOUNT_STATUSES,
  SUNLIGHT_ACCOUNT_STATUSES,
} from "../../types/installers";
import { PlanSaving } from "../../types/plans";
import { WindowBreakPoints } from "../../types/ui";
import useWindowDimensions from "../../hooks";

import {
  selectedPlansToDisplayAtom,
  systemLocationStateAtom,
} from "../../state/atoms/form";
import { appSettingsAtom, enabledStatesAtom } from "../../state/atoms/global";

import Tag, { TagType } from "../Tag";

const PlanCard: React.FC<{
  imgSrc: string;
  title: string;
  tagType: TagType;
  savingDetails?: PlanSaving;
  details: React.ReactNode;
  stripeAccountStatus?: STRIPE_ACCOUNT_STATUSES;
  sunlightAccountStatus?: SUNLIGHT_ACCOUNT_STATUSES;
  installer: Installer;
  tooltip: string;
}> = ({
  imgSrc,
  title,
  tagType,
  details,
  savingDetails,
  stripeAccountStatus,
  sunlightAccountStatus,
  installer,
  tooltip,
}) => {
  const { width } = useWindowDimensions();
  const { discountAlertThreshold, discountLockThreshold } =
    useRecoilValue(appSettingsAtom);
  const setAllPlansLocked = useSetRecoilState(allPlansLocked);
  const enabledStates = useRecoilValue(enabledStatesAtom);
  const systemLocationState = useRecoilValue(systemLocationStateAtom);

  const demoMode = useRecoilValue(isInDemoMode);
  const [selectedPlansToDisplay, setSelectedPlansToDisplay] = useRecoilState(
    selectedPlansToDisplayAtom
  );

  const discountLock = !!(
    savingDetails && savingDetails.percentage < discountLockThreshold * 100
  );
  const stripeConnectLock = !!(
    tagType === TagType.buy &&
    stripeAccountStatus !== STRIPE_ACCOUNT_STATUSES.DETAILS_SUBMITTED
  );
  const leaseLock =
    tagType === TagType.lease &&
    (!installer.leaseEnabled ||
      !enabledStates
        .filter((state) => state.leaseEnabled)
        .find((state) => state.abbreviation === systemLocationState));

  const loanLock =
    tagType === TagType.loan &&
    sunlightAccountStatus !== SUNLIGHT_ACCOUNT_STATUSES.VERIFIED;

  const planLock = demoMode
    ? false
    : discountLock || stripeConnectLock || leaseLock || loanLock;

  useEffect(() => {
    if (tagType === TagType.buy && !stripeConnectLock) {
      setAllPlansLocked(false);
    }

    if (tagType === TagType.lease && !discountLock) {
      setAllPlansLocked(false);
    }

    if (tagType === TagType.loan && !loanLock) {
      setAllPlansLocked(false);
    }
  }, [tagType, stripeConnectLock, discountLock, loanLock]);

  useEffect(() => {
    if (planLock) {
      setSelectedPlansToDisplay((selectedPlans) => ({
        ...selectedPlans,
        [tagType]: false,
      }));
    }
  }, [planLock, tagType]);

  const discountAlert =
    !planLock &&
    !!(
      savingDetails &&
      savingDetails.percentage < discountAlertThreshold * 100 &&
      savingDetails.percentage >= discountLockThreshold * 100
    );

  return (
    <div className={`col-12 col-md text-start plan`}>
      <div className="align-items-center position-relative px-0 py-2 py-md-0">
        {width < WindowBreakPoints.md && discountAlert && (
          <div className="plan-label alert">
            <img src={alertIcon} alt="Low Discount" />
            <span className="ml-1 d-sm-inline text-small font-weight-400">
              Low Discount
            </span>
          </div>
        )}
        {planLock && (
          <div className="plan-label lock">
            <img src={lockIcon} alt="Low Discount" />
            <span className="ml-1 text-small">Locked</span>
          </div>
        )}
        <div className="mt-1">
          <div className="h-100">
            <div className="d-block pt-025 pr-3 pr-md-0">
              <div className="d-flex m-0 p-0 mb-1 align-items-center h-100">
                <img src={imgSrc} alt={title} style={{ height: "1.2rem" }} />
                <small className="ml-1 d-block text-bold no-wrap">
                  {title}
                </small>
              </div>
            </div>
            <div className="mb-2">
              <Tag tagType={tagType}></Tag>
            </div>
            {width >= WindowBreakPoints.md ? (
              <div className="mt-2 mt-md-0">
                {planLock ? <h2>&nbsp;</h2> : details}
                {discountAlert && (
                  <div className="plan-label alert">
                    <img src={alertIcon} alt="Low Discount" />
                    <span className="ml-1 d-sm-inline text-small font-weight-400">
                      Low Discount
                    </span>
                  </div>
                )}
              </div>
            ) : (
              <div
                style={{
                  position: "absolute",
                  right: "0rem",
                  top: "1.5rem",
                }}
              >
                {planLock ? <></> : details}
              </div>
            )}
            <div style={{ marginTop: "0.9rem" }}>
              {planLock ? (
                <div className="mt-2 text-small">
                  {discountLock && (
                    <>
                      <div>Discount too low</div>
                      <div>Decrease quote to unlock</div>
                    </>
                  )}
                  {stripeConnectLock && (
                    <div>
                      To unlock this option, please complete account setup
                    </div>
                  )}
                  {leaseLock && (
                    <div>
                      <div>To enable contact</div>
                      <a
                        href={`mailto:partners@rhino-eco.com?subject=Unlock Rhino's lease for: ${installer.name}!`}
                      >
                        partners@rhino-eco.com
                      </a>
                    </div>
                  )}
                  {loanLock && (
                    <div>
                      <div>To enable contact</div>
                      <a
                        href={`mailto:partners@rhino-eco.com?subject=Enable loan for ${installer.name}!`}
                      >
                        partners@rhino-eco.com
                      </a>
                    </div>
                  )}
                </div>
              ) : savingDetails ? (
                <div
                  className="mt-2 text-small"
                  style={{
                    minHeight: width >= WindowBreakPoints.md ? "2rem" : "auto",
                  }}
                >
                  <Tooltip
                    html={
                      <div className="bg-white text-black m-0 p-1 max-w-400px text-start border border-secondary text-small rounded">
                        {tooltip}
                      </div>
                    }
                    size="small"
                    position="bottom"
                    trigger={
                      width >= WindowBreakPoints.md ? "mouseenter" : "click"
                    }
                    animation="shift"
                    transitionFlip={false}
                  >
                    <div className="data-hj-allow d-inline">
                      <span>
                        $
                        {savingDetails.lifeTimeSavings.toLocaleString("en-US", {
                          useGrouping: true,
                        })}
                      </span>{" "}
                      savings
                    </div>
                    <span className="ml-05">
                      <svg
                        style={{ marginBottom: "1px" }}
                        width="10"
                        height="10"
                        viewBox="0 0 9 9"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="4.5" cy="4.5" r="4.5" />
                        <path
                          d="M4.28748 3.04795C4.08524 3.04795 3.91895 2.98953 3.78861 2.87268C3.66277 2.75133 3.59985 2.60302 3.59985 2.42774C3.59985 2.24797 3.66277 2.09965 3.78861 1.9828C3.91895 1.86145 4.08524 1.80078 4.28748 1.80078C4.48523 1.80078 4.64703 1.86145 4.77287 1.9828C4.9032 2.09965 4.96837 2.24797 4.96837 2.42774C4.96837 2.60302 4.9032 2.75133 4.77287 2.87268C4.64703 2.98953 4.48523 3.04795 4.28748 3.04795ZM4.86051 3.43896V7.2007H3.70772V3.43896H4.86051Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </Tooltip>
                </div>
              ) : (
                <div className="mt-4" />
              )}
            </div>
            <div className="divider" style={{ marginTop: "0.9rem" }} />
            <label
              className="d-flex align-items-center"
              style={{ marginTop: "0.9rem" }}
            >
              <input
                type="checkbox"
                className="form-check-input check-darker check-smaller mt-0"
                checked={selectedPlansToDisplay[tagType]}
                onChange={(event) => {
                  setSelectedPlansToDisplay((selectedPlans) => ({
                    ...selectedPlans,
                    [tagType]: event.target.checked,
                  }));
                }}
                disabled={planLock}
              />
              <span
                className={`ml-1 text-black text-small ${
                  planLock ? "text-disabled" : ""
                }`}
              >
                Add to offer
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(PlanCard);

import React, { FunctionComponent, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useHistory, useLocation } from "react-router-dom";
import { Timestamp } from "firebase/firestore";

import { ReactComponent as BackArrow } from "../assets/images/arrow-back.svg";

import editIcon from "../assets/images/edit-icon.svg";
import { upsertSalesRepUser } from "../services/firebase";
import { getPhoneNumberValidationError } from "../services/utils";
import { appSettingsAtom, contextInstallerAtom } from "../state/atoms/global";
import { toastDataAtom } from "../state/atoms/ui";
import {
  myAccountFirstNameAtom,
  myAccountLastNameAtom,
  myAccountPhoneNumberAtom,
  myAccountRoleAtom,
  signedInUserAtom,
} from "../state/atoms/auth";
import { canSubmitMyAccountSelector } from "../state/selectors/auth";

import { ToastType } from "./Toast";
import { STRIPE_ACCOUNT_STATUSES } from "../types/installers";

const MyAccount: FunctionComponent<{}> = () => {
  const { search } = useLocation();
  const history = useHistory();

  const appSettings = useRecoilValue(appSettingsAtom);
  const [toastData, setToastData] = useRecoilState(toastDataAtom);
  const [signedInUser, setSignedInUser] = useRecoilState(signedInUserAtom);
  const contextInstaller = useRecoilValue(contextInstallerAtom);

  const [firstName, setFirstName] = useRecoilState(myAccountFirstNameAtom);
  const [lastName, setLastName] = useRecoilState(myAccountLastNameAtom);
  const [phoneNumber, setPhoneNumber] = useRecoilState(
    myAccountPhoneNumberAtom
  );
  const [role, setRole] = useRecoilState(myAccountRoleAtom);

  const [phoneNumberError, setPhoneNumberError] = useState("");

  const canSubmit = useRecoilValue(canSubmitMyAccountSelector);

  const [isEditingAccount, setIsEditingAccount] = useState(false);

  useEffect(() => {
    setFirstName(signedInUser?.firstName || "");
    setLastName(signedInUser?.lastName || "");
    setPhoneNumber(signedInUser?.phoneNumber || "");
    setRole(signedInUser?.role || "");
  }, []);

  if (!signedInUser || !contextInstaller) {
    return <></>;
  }

  const onSubmit = async () => {
    try {
      const updatedUser = {
        ...signedInUser,
        firstName: firstName || "",
        lastName: lastName || "",
        phoneNumber: phoneNumber || "",
        role: role || "",
        profileUpdated: true,
        updatedAt: Timestamp.now(),
      };
      await upsertSalesRepUser(updatedUser);
      setSignedInUser(updatedUser);
      setIsEditingAccount(false);
    } catch (err) {
      setToastData([
        ...toastData,
        {
          toastType: ToastType.info,
          header: "Could not update account",
          body: "Please try again later",
        },
      ]);
    }
  };

  const { stripeAccountStatus } = contextInstaller;

  return (
    <div>
      <div>
        <button
          className={`back-btn btn btn-sm btn-outline-secondary py-025 pl-1`}
          onClick={() => {
            history.push(`/step-1${search}`);
          }}
        >
          <span>
            <span className="mr-05" style={{ marginTop: "-2px" }}>
              <BackArrow />
            </span>
            Back
          </span>
        </button>
      </div>
      <div className="container-sm">
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            await onSubmit();
          }}
        >
          <div className="row pb-3 border-bottom">
            <div className="col-12 mt-3 mt-md-0">
              <h5 className="font-weight-600 d-inline-block">My account</h5>
              {!isEditingAccount ? (
                <>
                  <div
                    className="d-inline-block cursor-pointer"
                    role="button"
                    onClick={() => {
                      setIsEditingAccount(true);
                    }}
                  >
                    <img src={editIcon} alt="Edit" className="ml-2 mr-05" />
                    <small className="text-muted d-inline-block">Edit</small>
                  </div>
                  {signedInUser.isAdmin && (
                    <button
                      className="back-btn btn btn-sm btn-outline-primary py-025 float-right"
                      onClick={() => {
                        history.push("/stripe-connect");
                      }}
                    >
                      {!stripeAccountStatus && "Connect your account"}
                      {stripeAccountStatus ===
                        STRIPE_ACCOUNT_STATUSES.CREATED &&
                        "Finish your account setup"}
                      {stripeAccountStatus ===
                        STRIPE_ACCOUNT_STATUSES.DETAILS_SUBMITTED &&
                        "Payments Dashboard"}
                    </button>
                  )}
                </>
              ) : (
                <button
                  className="btn btn-tertiary btn-xs float-right"
                  disabled={!canSubmit}
                  onClick={onSubmit}
                >
                  Save
                </button>
              )}
            </div>
            <div className="col-12 col-md-6 mt-3">
              <div className="text-medium font-weight-600">
                First name<span className="text-primary ml-025">*</span>
              </div>
              <div
                className={`text-truncate pb-05 border-bottom ${
                  isEditingAccount ? "border-primary" : "border-transparent"
                }`}
              >
                {isEditingAccount ? (
                  <span className="inline-form-control-wrap">
                    <input
                      type="text"
                      className="w-100 text-truncate inline-form-control-sm"
                      id="first-name"
                      autoComplete="off"
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                    />
                  </span>
                ) : (
                  signedInUser?.firstName
                )}
              </div>
            </div>
            <div className="col-12 col-md-6 mt-3">
              <div className="text-medium font-weight-600">
                Last name<span className="text-primary ml-025">*</span>
              </div>
              <div
                className={`text-truncate pb-05 border-bottom ${
                  isEditingAccount ? "border-primary" : "border-transparent"
                }`}
              >
                {isEditingAccount ? (
                  <span className="inline-form-control-wrap">
                    <input
                      type="text"
                      className="w-100 text-truncate inline-form-control-sm"
                      id="last-name"
                      autoComplete="off"
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                    />
                  </span>
                ) : (
                  signedInUser?.lastName
                )}
              </div>
            </div>
            <div className="col-12 col-md-6 mt-3">
              <div className="text-medium font-weight-600">
                Role<span className="text-primary ml-025">*</span>
              </div>
              <div
                className={`text-truncate pb-05 border-bottom ${
                  isEditingAccount ? "border-primary" : "border-transparent"
                }`}
              >
                {isEditingAccount ? (
                  <span className="inline-form-control-wrap">
                    <select
                      className="w-100 text-truncate inline-form-control-sm"
                      style={{ marginLeft: "-4px" }}
                      id="role"
                      autoComplete="off"
                      value={role}
                      onChange={(e) => {
                        setRole(e.target.value);
                      }}
                    >
                      {appSettings.roles.map((role) => (
                        <option key={role.id} value={role.name}>
                          {role.name}
                        </option>
                      ))}
                      <option key={"other"} value={"Other"}>
                        Other
                      </option>
                    </select>
                  </span>
                ) : (
                  signedInUser?.role
                )}
              </div>
            </div>
            <div className="col-12 col-md-6 mt-3">
              <div className="text-medium font-weight-600">Phone</div>
              <div
                className={`text-truncate pb-05 border-bottom ${
                  isEditingAccount ? "border-primary" : "border-transparent"
                }`}
              >
                {isEditingAccount ? (
                  <span className="inline-form-control-wrap">
                    <input
                      type="text"
                      className="w-100 text-truncate inline-form-control-sm"
                      id="phone-number"
                      autoComplete="off"
                      value={phoneNumber}
                      onChange={(e) => {
                        const { value } = e.target;
                        setPhoneNumber(value);
                        setPhoneNumberError(
                          getPhoneNumberValidationError(value)
                        );
                      }}
                    />
                  </span>
                ) : signedInUser?.phoneNumber ? (
                  signedInUser?.phoneNumber
                ) : (
                  <span className="text-muted opacity-50">Not provided</span>
                )}
              </div>
              {phoneNumberError && (
                <div className="text-danger text-small">{phoneNumberError}</div>
              )}
            </div>
            <div className="col-12 col-md-6 mt-3">
              <div className="text-medium font-weight-600">Company</div>
              <div
                className={`text-truncate pb-05 border-bottom border-transparent ${
                  isEditingAccount ? "text-muted" : ""
                }`}
              >
                {contextInstaller?.name}
              </div>
            </div>
            <div className="col-12 col-md-6 mt-3">
              <div className="text-medium font-weight-600">Work email</div>
              <div
                className={`text-truncate pb-05 border-bottom border-transparent ${
                  isEditingAccount ? "text-muted" : ""
                }`}
              >
                {signedInUser?.email}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MyAccount;

import React, { FunctionComponent, useEffect } from "react";
import { Loading } from "./Loading";
import { getStripeConnectUrl } from "../services/firebase";

const StripeConnectRedirect: FunctionComponent<{}> = () => {
  useEffect(() => {
    const getUrl = async () => {
      try {
        const { result } = await getStripeConnectUrl();

        if (result?.stripeConnectUrl) {
          window.location.href = result.stripeConnectUrl;
        }
      } catch (err) {
        console.error(`Error: ${JSON.stringify(err)}`);
      }
    };

    getUrl();
  }, []);

  return <Loading></Loading>;
};

export default StripeConnectRedirect;

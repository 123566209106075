import React, { FunctionComponent } from "react";
import logo from "../assets/images/logo.svg";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";

export const Loading: FunctionComponent<
  React.HTMLAttributes<HTMLDivElement> & {
    showSignLoading?: boolean;
  }
> = ({ className, showSignLoading = false, ...props }) => {
  return (
    <div
      {...props}
      className={`loading-fullscreen d-flex align-items-center justify-content-center ${
        className ? className : ""
      }`}
    >
      <div>
        {showSignLoading ? (
          <>
            <img className="logo-loading" src={logo} alt="logo" />
            <div className="text-center font-weight-600 text-large">
              Proposal Engine
            </div>
            <div className="text-center font-weight-300">
              Financing just got personal
            </div>
          </>
        ) : (
          <div className="text-medium">
            <Lottie
              style={{ width: "5rem" }}
              animationData={loadingAnimation}
              loop
            />
          </div>
        )}
      </div>
    </div>
  );
};

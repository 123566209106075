import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import postalCodes from "postal-codes-js";
import { UsaStates } from "usa-states";

import loader from "../../assets/images/loader-white.gif";

import { sendDealProposal } from "../../services/firebase";

import { UsaState } from "../../types/proposal";

import {
  customerDetailsAddressAtom,
  customerDetailsCityAtom,
  customerDetailsEmailAtom,
  customerDetailsFirstNameAtom,
  customerDetailsIsSameAddressAtom,
  customerDetailsLastNameAtom,
  customerDetailsPhoneNumberAtom,
  customerDetailsStateAtom,
  customerDetailsZipAtom,
  proposalIdAtom,
  systemLocationAddressAtom,
  systemLocationCityAtom,
  systemLocationStateAtom,
  systemLocationZipAtom,
} from "../../state/atoms/form";
import {
  canSubmitStep3Selector,
  isCustomerDetailsEmailValidSelector,
  isCustomerDetailsPhoneNumberValidSelector,
  proposalSelector,
} from "../../state/selectors/form";

import { enabledStatesAtom } from "../../state/atoms/global";

const Step3: FunctionComponent<{}> = () => {
  const { search } = useLocation();
  const history = useHistory();

  let { states } = new UsaStates() as {
    states: UsaState[];
  };
  const enabledStates = useRecoilValue(enabledStatesAtom).filter(
    (state) => state.enabled
  );
  states = [
    ...enabledStates,
    ...states
      .filter(
        (state) =>
          !enabledStates.find(
            (enabledState) => enabledState.abbreviation === state.abbreviation
          )
      )
      .sort((a, b) => a.name.localeCompare(b.name)),
  ];

  const [customerDetailsFirstName, setCustomerDetailsFirstName] =
    useRecoilState(customerDetailsFirstNameAtom);
  const [customerDetailsLastName, setCustomerDetailsLastName] = useRecoilState(
    customerDetailsLastNameAtom
  );
  const [customerDetailsEmail, setCustomerDetailsEmail] = useRecoilState(
    customerDetailsEmailAtom
  );
  const isCustomerDetailsEmailValid = useRecoilValue(
    isCustomerDetailsEmailValidSelector
  );
  const [customerDetailsPhoneNumber, setCustomerDetailsPhoneNumber] =
    useRecoilState(customerDetailsPhoneNumberAtom);
  const isCustomerDetailsPhoneNumberValid = useRecoilValue(
    isCustomerDetailsPhoneNumberValidSelector
  );
  const [customerDetailsIsSameAddress, setCustomerDetailsIsSameAddress] =
    useRecoilState(customerDetailsIsSameAddressAtom);
  const [customerDetailsAddress, setCustomerDetailsAddress] = useRecoilState(
    customerDetailsAddressAtom
  );
  const [customerDetailsCity, setCustomerDetailsCity] = useRecoilState(
    customerDetailsCityAtom
  );
  const [customerDetailsState, setCustomerDetailsState] = useRecoilState(
    customerDetailsStateAtom
  );
  const [customerDetailsZip, setCustomerDetailsZip] = useRecoilState(
    customerDetailsZipAtom
  );

  const systemLocationAddress = useRecoilValue(systemLocationAddressAtom);
  const systemLocationCity = useRecoilValue(systemLocationCityAtom);
  const systemLocationState = useRecoilValue(systemLocationStateAtom);
  const systemLocationZip = useRecoilValue(systemLocationZipAtom);

  const canSubmitStep = useRecoilValue(canSubmitStep3Selector);
  const proposal = useRecoilValue(proposalSelector);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isZipValid, setIsZipValid] = useState(true);

  const setProposalId = useSetRecoilState(proposalIdAtom);

  useEffect(() => {
    if (customerDetailsZip) {
      const isValid = postalCodes.validate("US", customerDetailsZip);
      if (typeof isValid === "boolean") {
        setIsZipValid(isValid);
      } else {
        setIsZipValid(false);
      }
    } else {
      setIsZipValid(true);
    }
  }, [customerDetailsZip]);

  return (
    <>
      <h1>Customer&apos;s details</h1>
      <div className="form-group max-w-700px m-auto my-3 text-start">
        <h5 className="d-block d-md-none">Customer&apos;s details</h5>
        <div className="row">
          <div className="form-floating px-05 col-12 col-md-6">
            <input
              type="text"
              className="form-control"
              placeholder="First name"
              id="customer-first-name"
              value={customerDetailsFirstName || ""}
              onChange={(e) => setCustomerDetailsFirstName(e.target.value)}
            />
            <label htmlFor="customer-first-name">First name</label>
          </div>
          <div className="form-floating px-05 col-12 col-md-6 mt-3 mt-md-0">
            <input
              type="text"
              className="form-control"
              placeholder="Last name"
              id="customer-last-name"
              value={customerDetailsLastName || ""}
              onChange={(e) => setCustomerDetailsLastName(e.target.value)}
            />
            <label htmlFor="customer-last-name">Last name</label>
          </div>
          <div className="form-floating px-05 col-12 col-md-6 mt-3">
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              id="customer-email"
              value={customerDetailsEmail || ""}
              onChange={(e) => setCustomerDetailsEmail(e.target.value)}
            />
            <label htmlFor="customer-email">Email</label>
            {customerDetailsEmail && !isCustomerDetailsEmailValid && (
              <div className="col-12 mt-1">
                <div className="pl-1 text-primary text-small">
                  Invalid email
                </div>
              </div>
            )}
          </div>
          <div className="form-floating px-05 col-12 col-md-6 mt-3">
            <input
              type="tel"
              className="form-control"
              placeholder="Phone number"
              id="customer-phone-number"
              value={customerDetailsPhoneNumber || ""}
              onChange={(e) => setCustomerDetailsPhoneNumber(e.target.value)}
            />
            <label htmlFor="customer-phone-number">Phone number</label>
            {customerDetailsPhoneNumber && !isCustomerDetailsPhoneNumberValid && (
              <div className="px-05 col-12 mt-1">
                <div className="pl-1 text-primary text-small">
                  Invalid phone number
                </div>
              </div>
            )}
          </div>
          <div className="px-05 col-12 mt-3">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="customer-same-address"
                checked={customerDetailsIsSameAddress}
                onChange={(e) =>
                  setCustomerDetailsIsSameAddress(e.target.checked)
                }
              />
              <label
                className="form-check-label"
                htmlFor="customer-same-address"
              >
                Use system address
              </label>
            </div>
          </div>
          <div className="form-floating px-05 col-12 col-md-6 mt-3">
            <input
              type="text"
              className="form-control"
              placeholder="Address"
              id="customer-address"
              disabled={customerDetailsIsSameAddress}
              value={
                (customerDetailsIsSameAddress
                  ? systemLocationAddress
                  : customerDetailsAddress) || ""
              }
              onChange={(e) => setCustomerDetailsAddress(e.target.value)}
            />
            <label htmlFor="customer-address">Address</label>
          </div>
          <div className="form-floating px-05 col-12 col-md-6 mt-3">
            <input
              type="text"
              className="form-control"
              placeholder="City"
              id="customer-city"
              disabled={customerDetailsIsSameAddress}
              value={
                (customerDetailsIsSameAddress
                  ? systemLocationCity
                  : customerDetailsCity) || ""
              }
              onChange={(e) => setCustomerDetailsCity(e.target.value)}
            />
            <label htmlFor="customer-city">City</label>
          </div>
          <div className="form-floating px-05 col-12 col-md-6 mt-3">
            <select
              className="form-control"
              id="customer-state"
              value={
                (customerDetailsIsSameAddress
                  ? systemLocationState
                  : customerDetailsState) || ""
              }
              disabled={customerDetailsIsSameAddress}
              onChange={(e) => setCustomerDetailsState(e.target.value)}
            >
              <option disabled value="">
                Select state
              </option>
              {states.map(({ abbreviation, name, enabled }) => (
                <option
                  key={`state-${abbreviation}`}
                  value={abbreviation}
                  disabled={!enabled}
                >
                  {name}
                </option>
              ))}
            </select>
            <label htmlFor="state">State</label>
          </div>
          <div className="form-floating px-05 col-12 col-md-6 mt-3">
            <input
              type="text"
              className="form-control"
              placeholder="Zip Code"
              id="customer-zip"
              disabled={customerDetailsIsSameAddress}
              value={
                (customerDetailsIsSameAddress
                  ? systemLocationZip
                  : customerDetailsZip) || ""
              }
              onChange={(e) => setCustomerDetailsZip(e.target.value)}
            />
            <label htmlFor="customer-zip">Zip Code</label>
          </div>
          {!customerDetailsIsSameAddress && !isZipValid && (
            <>
              <div className="d-none d-md-block col-6" />
              <div className="px-05 col-12 col-md-6 mt-1">
                <div className="pl-1 text-primary text-small">Unrecognized</div>
              </div>
            </>
          )}
          <div className="px-05 col-12 mt-3">
            <button
              type="submit"
              className="btn btn-primary w-100 font-weight-600"
              disabled={!canSubmitStep || isSubmitting}
              onClick={async () => {
                setIsSubmitting(true);
                try {
                  if (proposal) {
                    const { status, result } = await sendDealProposal(proposal);
                    if (status === 200 && result?.proposalId) {
                      setProposalId(result.proposalId);
                      history.push(`/success${search}`);
                    } else {
                      console.error("Error sending proposal");
                      setIsSubmitting(false);
                    }
                  }
                } catch (err) {
                  console.error(`Error: ${JSON.stringify(err)}`);
                } finally {
                  setIsSubmitting(false);
                }
              }}
            >
              {isSubmitting ? (
                <img height="22px" src={loader} alt="Submitting..." />
              ) : (
                "Send Now"
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step3;

import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import {
  RecoilRoot,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";

import {
  getAppSettings,
  getInstallerFromList,
  getStates,
  getSalesRepUser,
  onAuthStateChangedHook,
  migrateCustomerData,
} from "./services/firebase";
import { removeURLParameter } from "./services/utils";
import {
  showSignLoadingAtom,
  signedInUserAtom,
  skipStripeConnectAtom,
  waitingForAuthAtom,
} from "./state/atoms/auth";
import {
  appSettingsAtom,
  contextInstallerAtom,
  contextInstallerIdAtom,
  contextRecaptchaError,
  contextRecaptchaExpire,
  contextRecaptchaToken,
  contextResetRecaptcha,
  contextShowStripeConnectTopBar,
  contextShowStripeConnectWizard,
  enabledStatesAtom,
} from "./state/atoms/global";
import { toastDataAtom, isInDemoMode } from "./state/atoms/ui";
import { Loading } from "./components/Loading";
import { Toast, ToastType } from "./components/Toast";
import Header from "./components/sections/Header";
import Stepper from "./components/sections/Stepper";
import Footer from "./components/sections/Footer";
import SignIn from "./components/auth/SignIn";
import SignUp from "./components/auth/SignUp";
import ContactEmail from "./components/auth/ContactEmail";
import LetsGetToKnow from "./components/auth/LetsGetToKnow";
import ResetPassword from "./components/auth/ResetPassword";
import ResetPasswordComplete from "./components/auth/ResetPasswordComplete";
import VerificationEmailSent from "./components/auth/VerificationEmailSent";
import StripeConnectWizard from "./components/auth/StripeConnectWizard";
import MyAccount from "./components/MyAccount";
import Steps from "./components/steps/Steps";
import Success from "./components/Success";
import StartFresh from "./components/StartFresh";
import PageNotFound from "./components/PageNotFound";
import { STRIPE_ACCOUNT_STATUSES } from "./types/installers";
import StripeConnectRedirect from "./components/StripeConnectRedirect";

const AUTH_ROUTES = [
  "sign-in",
  "sign-up",
  "reset-password",
  "reset-password-complete",
];

const WrappedApp: FunctionComponent<{}> = () => {
  const { pathname, search } = useLocation();
  const history = useHistory();

  const [waitingForAuth, setWaitingForAuth] =
    useRecoilState(waitingForAuthAtom);
  const [showSignLoading, setShowSignLoading] =
    useRecoilState(showSignLoadingAtom);
  const [signedInUser, setSignedInUser] = useRecoilState(signedInUserAtom);
  const [showStripeConnectWizard, setShowStripeConnectWizard] = useRecoilState(
    contextShowStripeConnectWizard
  );
  const [showStripeConnectTopBar, setShowStripeConnectTopBar] = useRecoilState(
    contextShowStripeConnectTopBar
  );

  const [toastData, setToastData] = useRecoilState(toastDataAtom);
  const setIsInDemoMode = useSetRecoilState(isInDemoMode);
  const skipStripeConnect = useRecoilValue(skipStripeConnectAtom);

  const setRecaptchaToken = useSetRecoilState(contextRecaptchaToken);
  const setRecaptchaExpire = useSetRecoilState(contextRecaptchaExpire);
  const setRecaptchaError = useSetRecoilState(contextRecaptchaError);
  const [resetRecaptcha, setResetCaptcha] = useRecoilState(
    contextResetRecaptcha
  );

  const setContextInstallerId = useSetRecoilState(contextInstallerIdAtom);
  const [contextInstaller, setContextInstaller] =
    useRecoilState(contextInstallerAtom);
  const setAppSettings = useSetRecoilState(appSettingsAtom);
  const setEnabledStates = useSetRecoilState(enabledStatesAtom);

  const [waitingForInstallerData, setWaitingForInstallerData] = useState(true);
  const [isProcessingStripeConnection, setIsProcessingStripeConnection] =
    useState(false);

  const queryParams = new URLSearchParams(location.search);
  const queryParamInstallerId = queryParams.get("installer");

  window.recaptchaSubmit = (token: string) => {
    setRecaptchaToken(token);
    setRecaptchaExpire(false);
    setRecaptchaError(false);
  };

  window.recaptchaExpire = () => {
    setRecaptchaExpire(false);
  };

  window.recaptchaError = () => {
    setRecaptchaError(true);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(search);

    if (queryParams.has("showEmailVerifiedModal")) {
      setShowSignLoading(true);
      history.push(
        removeURLParameter(`${pathname}${search}`, "showEmailVerifiedModal")
      );
    }
  }, [pathname, search]);

  useEffect(() => {
    setResetCaptcha(true);
  }, [pathname]);

  useEffect(() => {
    if (!resetRecaptcha) {
      return;
    }
    setResetCaptcha(false);
    setRecaptchaToken(null);
    if (typeof window?.grecaptcha?.reset === "function") {
      window?.grecaptcha?.reset();
    }
  }, [resetRecaptcha]);

  useEffect(() => {
    onAuthStateChangedHook(async (authUser) => {
      try {
        setWaitingForAuth(true);

        if (!authUser) {
          setSignedInUser(null);

          return;
        }

        const {
          uid,
          email: authEmail,
          emailVerified: authEmailVerified,
        } = authUser;

        if (!authEmail) {
          console.error("Authenticated user has no email");
          setToastData([
            ...toastData,
            {
              toastType: ToastType.info,
              header: "Something went wrong",
            },
          ]);

          return;
        }

        const dbUser = await getSalesRepUser(uid);

        if (!dbUser) {
          console.warn(
            "No user data found in database, please update your details to continue"
          );
          setToastData([
            ...toastData,
            {
              toastType: ToastType.success,
              header: "Thrilled to have you on board!",
            },
          ]);
        }

        const {
          installerId: dbInstallerId,
          profileUpdated: dbProfileUpdated,
          firstName: dbFirstName,
          lastName: dbLastName,
          role: dbRole,
          phoneNumber: dbPhoneNumber,
          hideStripeConnect: dbHideStripeConnect,
        } = dbUser || {};

        if (!dbInstallerId) {
          console.error("No installer is associated with this user");
          setToastData([
            ...toastData,
            {
              toastType: ToastType.info,
              header: "Something went wrong",
            },
          ]);

          return;
        }

        setSignedInUser({
          uid,
          installerId: dbInstallerId,
          email: authEmail,
          emailVerified: authEmailVerified || false,
          profileUpdated: dbProfileUpdated || false,
          firstName: dbFirstName,
          lastName: dbLastName,
          role: dbRole,
          phoneNumber: dbPhoneNumber,
          hideStripeConnect: dbHideStripeConnect,
          isAdmin: dbUser?.isAdmin,
        });
      } catch (err) {
        console.error("Error logging in", { err });
        setSignedInUser(null);
        setToastData([
          ...toastData,
          {
            toastType: ToastType.info,
            header: "Error logging in",
            body: "Please try refreshing the page or contact us for help",
          },
        ]);
      } finally {
        setWaitingForAuth(false);
      }
    });
  }, [setSignedInUser]);

  useEffect(() => {
    if (!signedInUser) {
      if (!AUTH_ROUTES.includes(pathname.replace("/", ""))) {
        return history.push(`/sign-in${search}`);
      }
    } else {
      const { profileUpdated, emailVerified } = signedInUser;
      if (!profileUpdated) {
        return history.push(`/lets-get-to-know-you${search}`);
      }

      if (!emailVerified) {
        return history.push(`/verification-email-sent${search}`);
      }

      if (showStripeConnectWizard && pathname !== "/stripe-connect") {
        return history.push(`/stripe-connect-wizard${search}`);
      }

      if (
        [
          ...AUTH_ROUTES,
          "lets-get-to-know-you",
          "verification-email-sent",
          "stripe-connect-wizard",
        ].includes(pathname.replace("/", ""))
      ) {
        return history.push(`/step-1${search}`);
      }
    }
  }, [signedInUser, showStripeConnectWizard, pathname]);

  useEffect(() => {
    if (signedInUser?.email?.includes("demo@rhino-eco.com")) {
      setIsInDemoMode(true);
      setShowStripeConnectTopBar(false);
      setShowStripeConnectWizard(false);
      return;
    }

    setShowStripeConnectTopBar(
      !!signedInUser?.isAdmin &&
        !AUTH_ROUTES.includes(pathname.replace("/", "")) &&
        contextInstaller?.stripeAccountStatus !==
          STRIPE_ACCOUNT_STATUSES.DETAILS_SUBMITTED
    );

    setShowStripeConnectWizard(
      !!signedInUser?.isAdmin &&
        !signedInUser.hideStripeConnect &&
        ![...AUTH_ROUTES, "stripe-connect"].includes(
          pathname.replace("/", "")
        ) &&
        !skipStripeConnect &&
        !contextInstaller?.stripeAccountStatus
    );
  }, [signedInUser, pathname, skipStripeConnect, contextInstaller]);

  useEffect(() => {
    const getInstaller = async () => {
      setWaitingForInstallerData(true);
      const installerId = signedInUser?.installerId || queryParamInstallerId;

      if (installerId) {
        const installer = await getInstallerFromList(installerId);

        setContextInstallerId(installerId);
        setContextInstaller(installer);
      }
      setWaitingForInstallerData(false);
    };
    getInstaller();
  }, [queryParamInstallerId, signedInUser]);

  useEffect(() => {
    const getSettings = async () => {
      const appSettings = await getAppSettings();
      if (appSettings) {
        setAppSettings(appSettings);
      }

      const states = await getStates();
      if (states) {
        setEnabledStates(states.filter((state) => state.enabled));
      }
    };
    getSettings();
  }, []);

  useEffect(() => {
    if (showSignLoading) {
      const loadingTimer = setTimeout(() => {
        setShowSignLoading(false);
      }, 2000);

      return () => {
        clearTimeout(loadingTimer);
      };
    }
  }, [showSignLoading]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return waitingForAuth || waitingForInstallerData || showSignLoading ? (
    <>
      <Loading showSignLoading={showSignLoading} />
      <Footer />
    </>
  ) : (
    <div className="pb-10">
      <div className="container px-4 px-md-0 h-100 position-relative">
        <Header />
        {toastData.length > 0 &&
          toastData.map(({ header, body, toastType, timeout }, idx) => (
            <Toast
              key={`toast-${idx}`}
              header={header}
              body={body}
              toastType={toastType}
              timeout={timeout}
            />
          ))}
      </div>
      {showStripeConnectTopBar && !showStripeConnectWizard && (
        <div
          className={`top-notification-bar p-1 mb-4 ${
            isProcessingStripeConnection ? "opacity-50" : ""
          }`}
        >
          You haven&apos;t completed the connect process.{" "}
          <span
            className={`link-white ${
              isProcessingStripeConnection ? "cursor-default" : ""
            }`}
            role="button"
            onClick={() => {
              setIsProcessingStripeConnection(true);
              setShowStripeConnectWizard(false);
              history.push("/stripe-connect");
              setIsProcessingStripeConnection(false);
            }}
          >
            Click here
          </span>{" "}
          to finish process.
        </div>
      )}

      <div className="container px-4 px-md-0 h-100">
        <Switch>
          <Route path="/something-went-wrong">
            <PageNotFound />
          </Route>
          {signedInUser ? (
            <>
              <Route path={`/(${AUTH_ROUTES.join("|")})`}>
                <Redirect to={`step-1${search}`} />
              </Route>
              <Route path="/lets-get-to-know-you">
                <LetsGetToKnow />
              </Route>
              <Route path="/verification-email-sent">
                <VerificationEmailSent />
              </Route>
              <Route path="/stripe-connect">
                <StripeConnectRedirect />
              </Route>
              <Route path="/stripe-connect-wizard">
                <StripeConnectWizard />
              </Route>
              <Route path="/my-account">
                <MyAccount />
              </Route>
              <Route path="/step-:stepId">
                <Stepper />
              </Route>
              <Route path="/success">
                <Success />
              </Route>
              <section id="section-page" className="mt-4">
                <Route path="/step-:stepId">
                  <Steps />
                </Route>
                <Route path="/start-fresh">
                  <StartFresh />
                </Route>
                <Route path="/" exact>
                  <Redirect to={`step-1${search}`} />
                </Route>
              </section>
            </>
          ) : (
            <>
              <Route path={`/sign-in`}>
                <SignIn />
              </Route>
              <Route path={`/sign-up`}>
                {queryParamInstallerId && contextInstaller?.enabled ? (
                  <SignUp />
                ) : (
                  <ContactEmail />
                )}
              </Route>
              <Route path="/reset-password">
                <ResetPassword />
              </Route>
              <Route path="/reset-password-complete">
                <ResetPasswordComplete />
              </Route>
              <Route path="/" exact>
                <Redirect to={`sign-in${search}`} />
              </Route>
            </>
          )}
        </Switch>
      </div>
      <Footer />
    </div>
  );
};

const App: FunctionComponent<{}> = () => {
  return (
    <RecoilRoot>
      <Router>
        <Switch>
          <Route
            path="/admin/customers/migrate"
            render={() => (
              <div>
                <button
                  onClick={async () => {
                    try {
                      await migrateCustomerData(true);
                      alert("DONE!");
                    } catch (err) {
                      console.error("Something went wrong", err);
                    }
                  }}
                >
                  Migrate Customers with Selected Plans To Display
                </button>
                <button
                  onClick={async () => {
                    try {
                      await migrateCustomerData(false);
                      alert("DONE!");
                    } catch (err) {
                      console.error("Something went wrong", err);
                    }
                  }}
                >
                  Rollback Customers with Selected Plans To Display
                </button>
              </div>
            )}
          ></Route>
          <Route path="*">
            <WrappedApp />
          </Route>
        </Switch>
      </Router>
    </RecoilRoot>
  );
};

export default App;

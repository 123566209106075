import React, { FunctionComponent, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import eye from "../../assets/images/eye.svg";
import eyeSlash from "../../assets/images/eye-slash.svg";
import { signUserIn } from "../../services/firebase";

import {
  showSignLoadingAtom,
  signInEmailAtom,
  signInEmailBlurredAtom,
  signInEmailErrorAtom,
  signInPasswordAtom,
  signInRememberMeAtom,
} from "../../state/atoms/auth";
import { toastDataAtom } from "../../state/atoms/ui";
import { canSignInSelector } from "../../state/selectors/auth";
import { SubmissionStates } from "../../types/ui";

import { ToastType } from "../Toast";
import { AuthErrorCodes } from "firebase/auth";
import { getEmailValidationError } from "../../services/utils";
import {
  contextRecaptchaToken,
  contextResetRecaptcha,
} from "../../state/atoms/global";

const SignIn: FunctionComponent<{}> = () => {
  const { search } = useLocation();

  const [toastData, setToastData] = useRecoilState(toastDataAtom);
  const setShowSignLoading = useSetRecoilState(showSignLoadingAtom);

  const [signInEmail, setSignInEmail] = useRecoilState(signInEmailAtom);
  const [signInPassword, setSignInPassword] =
    useRecoilState(signInPasswordAtom);
  const [signInRememberMe, setSignInRememberMe] =
    useRecoilState(signInRememberMeAtom);
  const canSignIn = useRecoilValue(canSignInSelector);

  const [signInEmailError, setSignInEmailError] =
    useRecoilState(signInEmailErrorAtom);
  const [signInEmailBlurred, setSignInEmailBlurred] = useRecoilState(
    signInEmailBlurredAtom
  );

  const [signInSubmissionStage, setSignInSubmissionStage] =
    useState<SubmissionStates>(SubmissionStates.NONE);

  const [passwordVisible, setPasswordVisible] = useState(false);

  const recaptchaToken = useRecoilValue(contextRecaptchaToken);
  const setResetRecaptcha = useSetRecoilState(contextResetRecaptcha);

  const togglePasswordVisiblity = () => {
    setPasswordVisible(!passwordVisible);
  };

  useEffect(() => {
    const sendSignIn = async () => {
      if (signInSubmissionStage !== SubmissionStates.SUBMITTING) {
        return;
      }

      if (recaptchaToken) {
        setResetRecaptcha(true);
        if (canSignIn) {
          try {
            setSignInEmailError("");
            await signUserIn(signInEmail, signInPassword);
            setSignInSubmissionStage(SubmissionStates.SUCCESS);
            setShowSignLoading(true);
          } catch (err) {
            const { code } = (err as { code: string }) || {};
            setSignInSubmissionStage(SubmissionStates.ERROR);
            setShowSignLoading(false);
            let errorMessage = "Could not sign you in";
            switch (code) {
              case AuthErrorCodes.USER_DELETED:
                errorMessage = "Unrecognized email";
                break;
              case AuthErrorCodes.INVALID_PASSWORD:
                errorMessage = "Incorrect email or password";
                break;
              default:
                break;
            }
            setSignInEmailBlurred(true);
            setSignInEmailError(errorMessage);
            setToastData([
              ...toastData,
              {
                toastType: ToastType.info,
                header: "Could not sign you in",
              },
            ]);
          }
        }
      }
    };
    sendSignIn();
  }, [recaptchaToken, signInSubmissionStage]);

  useEffect(() => {
    if (recaptchaToken) {
      setSignInSubmissionStage(SubmissionStates.SUBMITTING);
    }
  }, [recaptchaToken]);

  const onSubmit = async (
    evt: React.FormEvent<HTMLFormElement | HTMLButtonElement>
  ) => {
    evt.preventDefault();
    window.grecaptcha.execute();
  };

  return (
    <div className="container-sm text-center m-auto">
      <h5 className="font-weight-600">Log in</h5>
      <form onSubmit={onSubmit}>
        <div className="row my-2">
          <div className="form-floating col-12">
            <input
              type="email"
              className="form-control"
              placeholder="Work email"
              autoComplete="email"
              id="email"
              value={signInEmail}
              onFocus={() => {
                setSignInEmailError("");
                setSignInEmailBlurred(false);
              }}
              onChange={(e) => {
                const { value } = e.target;
                setSignInEmail(value);
                setSignInEmailError(getEmailValidationError(value, true));
              }}
              onBlur={() => setSignInEmailBlurred(true)}
              disabled={signInSubmissionStage === SubmissionStates.SUBMITTING}
            />
            <label htmlFor="email">
              Work email<span className="text-primary ml-025">*</span>
            </label>
            {signInEmailBlurred && signInEmailError && (
              <div className="pl-1 text-start text-primary text-small">
                {signInEmailError}
              </div>
            )}
          </div>
          <div className="form-floating col-12 mt-2">
            <input
              type={passwordVisible ? "text" : "password"}
              className="form-control"
              placeholder="Password"
              autoComplete="current-password"
              id="password"
              value={signInPassword}
              onChange={(e) => {
                const { value } = e.target;
                setSignInPassword(value);
              }}
              disabled={signInSubmissionStage === SubmissionStates.SUBMITTING}
            />
            <label htmlFor="password">
              Password<span className="text-primary ml-025">*</span>
            </label>
            <div className="show-hide-password">
              <div
                role="button"
                onClick={togglePasswordVisiblity}
                className="show-password-button"
              >
                <img
                  alt=""
                  className="eye-icon"
                  src={passwordVisible ? eyeSlash : eye}
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
          <div className="col-12 mt-2 d-flex justify-content-between">
            <div className="form-check">
              <input
                className="form-check-input data-hj-allow"
                type="checkbox"
                id="remember-me"
                autoComplete="off"
                checked={signInRememberMe}
                onChange={(e) => setSignInRememberMe(e.target.checked)}
                disabled={signInSubmissionStage === SubmissionStates.SUBMITTING}
              />
              <label className="form-check-label" htmlFor="remember-me">
                Remember me
              </label>
            </div>
            <div>
              <Link to={`/reset-password${search}`}>Forgot password</Link>
            </div>
          </div>
          <div className="col-12 mt-2">
            <button
              type="submit"
              className="btn btn-primary w-100 font-weight-600"
              disabled={
                !canSignIn ||
                signInSubmissionStage === SubmissionStates.SUBMITTING
              }
              onSubmit={(evt) => onSubmit(evt)}
            >
              Continue
            </button>
          </div>
        </div>
      </form>
      Don&apos;t have a user? <Link to={`/sign-up${search}`}>Sign up</Link>
    </div>
  );
};

export default SignIn;

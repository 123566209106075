import React, { FunctionComponent } from "react";
import { useRecoilValue } from "recoil";
import { useHistory, useLocation } from "react-router-dom";
import Lottie from "lottie-react";

import sunAnimation from "../assets/animations/better-sun.json";
import { proposalIdAtom } from "../state/atoms/form";

const Success: FunctionComponent<{}> = () => {
  const { search } = useLocation();
  const history = useHistory();

  const proposalId = useRecoilValue(proposalIdAtom);

  return (
    <div className="text-center rhino_animate__animated rhino_animate__fadeIn">
      <h1 className="m-0">Today just got brighter!</h1>
      <div className={`m-auto w-50 w-lg-25 w-xl-33 aspect-ratio-1-1`}>
        <Lottie animationData={sunAnimation} loop />
      </div>
      <h6 className="mb-1 font-weight-600">Your proposal was sent</h6>
      <h6 className="mb-3 font-weight-500">Proposal ID: {proposalId}</h6>
      <button
        className="btn btn-primary px-4"
        onClick={() => {
          history.push(`/start-fresh${search}`);
        }}
      >
        Create another proposal
      </button>
    </div>
  );
};

export default Success;

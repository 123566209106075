import { selector } from "recoil";
import {
  isValidEmail,
  isValidPassword,
  isValidPhoneNumber,
} from "../../services/utils";
import {
  letsGetToKnowFirstNameAtom,
  letsGetToKnowLastNameAtom,
  letsGetToKnowPhoneNumberAtom,
  letsGetToKnowRoleAtom,
  myAccountFirstNameAtom,
  myAccountLastNameAtom,
  myAccountPhoneNumberAtom,
  myAccountRoleAtom,
  signInEmailAtom,
  signInPasswordAtom,
  signUpEmailAtom,
  signUpPasswordAtom,
} from "../atoms/auth";

export const canSignInSelector = selector<boolean>({
  key: "canSignInSelector",
  get: ({ get }) => {
    const signInEmail = get(signInEmailAtom);
    const signInPassword = get(signInPasswordAtom);

    return !!(isValidEmail(signInEmail, true) && signInPassword);
  },
});

export const canSignUpSelector = selector<boolean>({
  key: "canSignUpSelector",
  get: ({ get }) => {
    const signUpEmail = get(signUpEmailAtom);
    const signUpPassword = get(signUpPasswordAtom);

    return !!(isValidEmail(signUpEmail) && isValidPassword(signUpPassword));
  },
});

export const canSubmitLetsGetToKnowSelector = selector<boolean>({
  key: "canSubmitLetsGetToKnowSelector",
  get: ({ get }) => {
    const firstName = get(letsGetToKnowFirstNameAtom);
    const lastName = get(letsGetToKnowLastNameAtom);
    const phoneNumber = get(letsGetToKnowPhoneNumberAtom);
    const role = get(letsGetToKnowRoleAtom);

    return !!(
      firstName &&
      lastName &&
      (phoneNumber ? isValidPhoneNumber(phoneNumber) : true) &&
      role
    );
  },
});

export const canSubmitMyAccountSelector = selector<boolean>({
  key: "canSubmitMyAccountSelector",
  get: ({ get }) => {
    const firstName = get(myAccountFirstNameAtom);
    const lastName = get(myAccountLastNameAtom);
    const phoneNumber = get(myAccountPhoneNumberAtom);
    const role = get(myAccountRoleAtom);

    return !!(
      firstName &&
      lastName &&
      (phoneNumber ? isValidPhoneNumber(phoneNumber) : true) &&
      role
    );
  },
});

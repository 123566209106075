import React, { FunctionComponent, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import * as CompanyEmailValidator from "company-email-validator";
import { Timestamp } from "firebase/firestore";
import { AuthErrorCodes } from "firebase/auth";

import eye from "../../assets/images/eye.svg";
import eyeSlash from "../../assets/images/eye-slash.svg";
import {
  upsertSalesRepUser,
  signUserUp,
  salesRepPostSignup,
} from "../../services/firebase";
import {
  getEmailValidationError,
  getPasswordValidationError,
} from "../../services/utils";

import {
  showSignLoadingAtom,
  signUpEmailAtom,
  signUpEmailBlurredAtom,
  signUpEmailErrorAtom,
  signUpPasswordAtom,
} from "../../state/atoms/auth";
import {
  contextInstallerAtom,
  contextRecaptchaToken,
  contextResetRecaptcha,
} from "../../state/atoms/global";
import { toastDataAtom } from "../../state/atoms/ui";
import { canSignUpSelector } from "../../state/selectors/auth";
import { ToastType } from "../Toast";
import { SubmissionStates } from "../../types/ui";

const NO_COMPANY_EMAIL_ERROR_CODE = "no-company-email";

const SignUp: FunctionComponent<{}> = () => {
  const { search } = useLocation();
  const [toastData, setToastData] = useRecoilState(toastDataAtom);
  const setShowSignLoading = useSetRecoilState(showSignLoadingAtom);

  const queryParams = new URLSearchParams(location.search);
  const queryParamInstallerId = queryParams.get("installer");

  const contextInstaller = useRecoilValue(contextInstallerAtom);

  const [signUpEmail, setSignUpEmail] = useRecoilState(signUpEmailAtom);
  const [signUpPassword, setSignUpPassword] =
    useRecoilState(signUpPasswordAtom);
  const canSignUp = useRecoilValue(canSignUpSelector);

  const [signUpSubmissionStage, setSignUpSubmissionStage] =
    useState<SubmissionStates>(SubmissionStates.NONE);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const [signUpEmailError, setSignUpEmailError] =
    useRecoilState(signUpEmailErrorAtom);
  const [signUpEmailBlurred, setSignUpEmailBlurred] = useRecoilState(
    signUpEmailBlurredAtom
  );
  const recaptchaToken = useRecoilValue(contextRecaptchaToken);
  const setResetRecaptcha = useSetRecoilState(contextResetRecaptcha);

  const [signUpPasswordError, setSignUpPasswordError] = useState("");

  const togglePasswordVisiblity = () => {
    setPasswordVisible(!passwordVisible);
  };

  useEffect(() => {
    const sendSignUp = async () => {
      if (signUpSubmissionStage !== SubmissionStates.SUBMITTING) {
        return;
      }

      if (recaptchaToken) {
        setResetRecaptcha(true);
        try {
          if (!CompanyEmailValidator.isCompanyEmail(signUpEmail)) {
            throw { code: NO_COMPANY_EMAIL_ERROR_CODE };
          }
          if (canSignUp && queryParamInstallerId) {
            setSignUpEmailError("");
            const { user } = await signUserUp(signUpEmail, signUpPassword);

            if (!user) {
              setSignUpSubmissionStage(SubmissionStates.ERROR);

              setToastData([
                ...toastData,
                {
                  toastType: ToastType.info,
                  header: "Something went wrong",
                },
              ]);

              return;
            }

            const { emailVerified, uid, email } = user;

            if (!uid || !email) {
              setSignUpSubmissionStage(SubmissionStates.ERROR);

              setToastData([
                ...toastData,
                {
                  toastType: ToastType.info,
                  header: "Something went wrong",
                },
              ]);

              return;
            }

            setShowSignLoading(true);

            await upsertSalesRepUser({
              uid,
              email,
              emailVerified,
              installerId: queryParamInstallerId,
              profileUpdated: false,
              createdAt: Timestamp.now(),
            });

            const { status, result } = await salesRepPostSignup();

            if (status === 200) {
              if (result?.isAdmin) {
                window.location.reload();
              }
            } else {
              console.error("Error sending proposal");
            }

            setSignUpSubmissionStage(SubmissionStates.SUCCESS);
            setToastData([
              ...toastData,
              {
                toastType: ToastType.success,
                header: "Thrilled to have you on board!",
              },
            ]);
          }
        } catch (err) {
          const { code } = (err as { code: string }) || {};
          setShowSignLoading(false);
          setSignUpSubmissionStage(SubmissionStates.ERROR);
          let errorMessage = "Could not sign you up";
          switch (code) {
            case AuthErrorCodes.EMAIL_EXISTS:
              errorMessage = "This email is already registered";
              break;
            case AuthErrorCodes.INVALID_PASSWORD:
              errorMessage = "Incorrect email or password";
              break;
            case NO_COMPANY_EMAIL_ERROR_CODE:
              errorMessage = "Please enter work email";
              break;
            default:
              break;
          }
          setSignUpEmailError(errorMessage);
          setSignUpEmailBlurred(true);
          setToastData([
            ...toastData,
            {
              toastType: ToastType.info,
              header: "Could not sign you up",
            },
          ]);
        }
      }
    };
    sendSignUp();
  }, [recaptchaToken, signUpSubmissionStage]);

  useEffect(() => {
    if (recaptchaToken) {
      setSignUpSubmissionStage(SubmissionStates.SUBMITTING);
    }
  }, [recaptchaToken]);

  const onSubmit = async (
    evt: React.FormEvent<HTMLFormElement | HTMLButtonElement>
  ) => {
    evt.preventDefault();
    window.grecaptcha.execute();
  };

  return (
    <div className="container-sm text-center m-auto">
      <h5 className="font-weight-600">
        Welcome Team
        {`${contextInstaller?.name ? " " + contextInstaller.name : ""}`}!
      </h5>
      <div className="w-75 m-auto my-2 font-weight-300">
        Sign up to get your customers
        <br />
        tailored solar financing offers
      </div>
      <form onSubmit={onSubmit}>
        <div className="row my-2">
          <div className="form-floating col-12">
            <input
              type="email"
              className="form-control"
              placeholder="Work email"
              autoComplete="email"
              id="email"
              value={signUpEmail}
              onFocus={() => setSignUpEmailBlurred(false)}
              onChange={(e) => {
                const { value } = e.target;
                setSignUpEmail(value);
                setSignUpEmailError(getEmailValidationError(value));
              }}
              onBlur={() => setSignUpEmailBlurred(true)}
              disabled={signUpSubmissionStage === SubmissionStates.SUBMITTING}
            />
            <label htmlFor="email">
              Work email<span className="text-primary ml-025">*</span>
            </label>
            {signUpEmailBlurred && signUpEmailError && (
              <div className="pl-1 text-start text-primary text-small">
                {signUpEmailError}
              </div>
            )}
          </div>
          <div className="form-floating col-12 mt-2">
            <input
              type={passwordVisible ? "text" : "password"}
              className="form-control"
              placeholder="Password"
              autoComplete="current-password"
              id="password"
              value={signUpPassword}
              onFocus={() => {
                setSignUpPasswordError(
                  getPasswordValidationError(signUpPassword)
                );
              }}
              onChange={(e) => {
                const { value } = e.target;
                setSignUpPassword(value);
                setSignUpPasswordError(getPasswordValidationError(value));
              }}
              disabled={signUpSubmissionStage === SubmissionStates.SUBMITTING}
            />
            <label htmlFor="password">
              Password<span className="text-primary ml-025">*</span>
            </label>
            <div className="show-hide-password">
              <div
                role="button"
                onClick={togglePasswordVisiblity}
                className="show-password-button"
              >
                <img
                  alt=""
                  className="eye-icon"
                  src={passwordVisible ? eyeSlash : eye}
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
          {signUpPasswordError && (
            <div className="pl-1 text-start text-primary text-small">
              {signUpPasswordError}
            </div>
          )}
          <div className="col-12 mt-2">
            <button
              type="submit"
              className="btn btn-primary w-100 font-weight-600"
              disabled={
                !canSignUp ||
                signUpSubmissionStage === SubmissionStates.SUBMITTING
              }
              onSubmit={onSubmit}
            >
              Continue
            </button>
          </div>
        </div>
      </form>
      Already a user? <Link to={`/sign-in${search}`}>Log in</Link>
    </div>
  );
};

export default SignUp;

import React, { FunctionComponent, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { openInviteLead } from "../../services/firebase";
import { getEmailValidationError, isValidEmail } from "../../services/utils";

import addUserIcon from "../../assets/images/add-user-icon.svg";
import { toastDataAtom } from "../../state/atoms/ui";

import { ToastType } from "../Toast";
import { SubmissionStates } from "../../types/ui";
import {
  contextRecaptchaToken,
  contextResetRecaptcha,
} from "../../state/atoms/global";

const ContactEmail: FunctionComponent<{}> = () => {
  const { search } = useLocation();
  const [toastData, setToastData] = useRecoilState(toastDataAtom);

  const [contactEmail, setContactEmail] = useState("");
  const [canSubmitContactEmail, setCanSubmitContactEmail] = useState(false);
  const [constactEmailSubmissionStage, setContactEmailSubmissionStage] =
    useState<SubmissionStates>(SubmissionStates.NONE);
  const recaptchaToken = useRecoilValue(contextRecaptchaToken);
  const setResetRecaptcha = useSetRecoilState(contextResetRecaptcha);

  const [contactEmailError, setContactEmailError] = useState("");
  const [contactEmailBlurred, setContactEmailBlurred] = useState(false);

  useEffect(() => {
    setCanSubmitContactEmail(isValidEmail(contactEmail, true));
  }, [contactEmail]);

  useEffect(() => {
    const sendContactEmail = async () => {
      if (constactEmailSubmissionStage !== SubmissionStates.SUBMITTING) {
        return;
      }

      if (recaptchaToken) {
        setResetRecaptcha(true);
        try {
          if (canSubmitContactEmail) {
            setContactEmailError("");
            await openInviteLead({ email: contactEmail, recaptchaToken });
            setContactEmailSubmissionStage(SubmissionStates.SUCCESS);
            setToastData([
              ...toastData,
              {
                toastType: ToastType.success,
                header: "We got it!",
                body: "We'll get in touch with you soon.",
              },
            ]);
          }
          setContactEmailSubmissionStage(SubmissionStates.SUCCESS);
        } catch (err) {
          setContactEmailSubmissionStage(SubmissionStates.ERROR);
          setToastData([
            ...toastData,
            {
              toastType: ToastType.info,
              header: "Something went wrong",
              body: "Please try again later.",
            },
          ]);
        }
      }
    };

    sendContactEmail();
  }, [constactEmailSubmissionStage, recaptchaToken]);

  useEffect(() => {
    if (recaptchaToken) {
      setContactEmailSubmissionStage(SubmissionStates.SUBMITTING);
    }
  }, [recaptchaToken]);

  const onSubmitContactEmail = async (
    evt: React.FormEvent<HTMLFormElement | HTMLButtonElement>
  ) => {
    evt.preventDefault();
    window.grecaptcha.execute();
  };

  return (
    <div className="container-sm text-center m-auto">
      <div
        className="card card-body mt-4 mb-2 p-4 rounded m-auto"
        style={{ maxWidth: "23.5rem" }}
      >
        <div className="m-auto rounded-circle bg-tertiary p-2 d-flex justify-content-center align-items-center">
          <img src={addUserIcon} alt="" width="22px" />
        </div>
        <div className="my-2">
          Our service is by invite only. Check your inbox if you already
          received an invite or ask your manager for an invite link.
        </div>
        <div>
          You can also leave us your email, and we&apos;ll get back to you.
        </div>
        <form onSubmit={onSubmitContactEmail}>
          <div className="row mt-4">
            <div className="form-floating col-12">
              <input
                type="email"
                className="form-control"
                placeholder="Work email"
                autoComplete="email"
                id="email"
                value={contactEmail}
                onFocus={() => setContactEmailBlurred(false)}
                onChange={(e) => {
                  const { value } = e.target;
                  setContactEmail(value);
                  setContactEmailError(getEmailValidationError(value, false));
                }}
                onBlur={() => setContactEmailBlurred(true)}
                disabled={
                  constactEmailSubmissionStage === SubmissionStates.SUBMITTING
                }
              />
              <label htmlFor="email">
                Work email<span className="text-primary ml-025">*</span>
              </label>
              {contactEmailBlurred && contactEmailError && (
                <div className="pl-1 text-start text-primary text-small">
                  {contactEmailError}
                </div>
              )}
            </div>
            <div className="col-12 mt-2">
              <button
                type="submit"
                className="btn btn-primary w-100 font-weight-600"
                disabled={
                  !canSubmitContactEmail ||
                  constactEmailSubmissionStage === SubmissionStates.SUBMITTING
                }
                onSubmit={onSubmitContactEmail}
              >
                Send
              </button>
            </div>
          </div>
        </form>
      </div>
      Already a user? <Link to={`/sign-in${search}`}>Log in</Link>
    </div>
  );
};

export default ContactEmail;

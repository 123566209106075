import { LoanDetails, PlanSaving } from "./plans";

export const generateMockLoan = ({
  leaseMonthlyPayment,
  leaseSaving,
}: {
  leaseMonthlyPayment: number;
  leaseSaving: PlanSaving;
}): LoanDetails =>
  ({
    monthlyPayment: leaseMonthlyPayment * 0.95,
    saving: {
      lifeTimeSavings: Math.floor(leaseSaving.lifeTimeSavings * 1.05),
    },
  } as LoanDetails);

import Lottie from "lottie-react";
import React, { FunctionComponent, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

import animation from "../../assets/animations/connect-anim.json";
import checkIcon from "../../assets/images/check-circle-contained.svg";
import { updateSalesRepUser } from "../../services/firebase";
import {
  signedInUserAtom,
  skipStripeConnectAtom,
} from "../../state/atoms/auth";

const StripeConnectWizard: FunctionComponent<{}> = () => {
  const history = useHistory();

  const signedInUser = useRecoilValue(signedInUserAtom);
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);

  const setSkipStripeConnect = useSetRecoilState(skipStripeConnectAtom);

  if (!signedInUser) {
    return null;
  }

  return (
    <div className="container p-0 text-center m-auto">
      <div
        className="text-right text-medium link"
        role="button"
        onClick={async () => {
          setSkipStripeConnect(true);
          if (doNotShowAgain) {
            await updateSalesRepUser(signedInUser.uid, {
              hideStripeConnect: true,
            });
          }
        }}
      >
        {doNotShowAgain ? "Skip forever" : "Skip for now"}
      </div>
      <h5 className="font-weight-600 mt-2 mt-lg-0">Let&apos;s connect</h5>
      <div className="w-100 w-lg-75 m-auto my-2 font-weight-300">
        Complete the onboarding process to enable
        <br />
        purchases through Rhino and get paid seamlessly
      </div>
      <div className="row mt-4">
        <div className="col-12 col-lg-6">
          <div
            style={{
              height: "20rem",
              borderRadius: "1rem",
              backgroundColor: "white",
            }}
            className="float-lg-right"
          >
            <Lottie style={{ height: "100%" }} animationData={animation} loop />
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="max-w-400px text-start m-auto mt-4 m-lg-0 mt-lg-4">
            <div className="mb-2 d-flex">
              <img
                src={checkIcon}
                alt=""
                style={{ height: "1.1rem" }}
                className="mr-1 mt-025"
              />
              <div>Frictionless ACH Debit payments</div>
            </div>
            <div className="mb-2 d-flex">
              <img
                src={checkIcon}
                alt=""
                style={{ height: "1.1rem" }}
                className="mr-1 mt-025"
              />
              <div>Give your customers a one-stop shop</div>
            </div>
            <div className="mb-6 d-flex">
              <img
                src={checkIcon}
                alt=""
                style={{ height: "1.1rem" }}
                className="mr-1 mt-025"
              />
              <div>
                Get assurance that your bill will be paid with our prequalify
                check
              </div>
            </div>
            <button
              className="btn btn-primary btn-block w-100"
              onClick={() => {
                history.push("/stripe-connect");
              }}
            >
              Connect Now
            </button>
          </div>
        </div>
      </div>
      <div className="d-flex mt-2">
        <div className="m-auto form-check">
          <input
            className="form-check-input data-hj-allow"
            type="checkbox"
            id="remember-me"
            autoComplete="off"
            checked={doNotShowAgain}
            onChange={(e) => setDoNotShowAgain(e.target.checked)}
          />
          <label className="form-check-label" htmlFor="remember-me">
            Do not show me this again
          </label>
        </div>
      </div>
    </div>
  );
};

export default StripeConnectWizard;

export enum STRIPE_ACCOUNT_STATUSES {
  CREATED = "created",
  DETAILS_SUBMITTED = "details_submitted",
}

export enum SUNLIGHT_ACCOUNT_STATUSES {
  KEYS_ADDED = "keys_added",
  VERIFIED = "verified",
  ERROR = "error",
}

export interface Installer {
  id: string;
  name?: string;
  enabled?: boolean;
  stripeAccountStatus?: STRIPE_ACCOUNT_STATUSES;
  sunlightAccountStatus?: SUNLIGHT_ACCOUNT_STATUSES;
  leaseEnabled?: boolean;
}
